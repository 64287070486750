h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600;
  font-family: 'Borna';
  letter-spacing: 0px;
  color: $secondary-color;
}
h1, .h1 {
  font-size: 30px;
  line-height: 36px;

  @include media-breakpoint-up(lg) {
    font-size: 40px;
    line-height: 48px;
  }
}
h2, .h2 {
  font-size: 26px;
  line-height: 32px;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
    line-height: 32px;
  }
}
h4, .h4 {
  font-size: 22px;
  line-height: 28px;

  @include media-breakpoint-up(lg) {
    font-size: 28px;
    line-height: 36px;
  }
}

a {
  color: $secondary-color;

  &:hover {
    color: $secondary-color;
  }
}

.ov-x-hidden {
  overflow-x: hidden;
}


.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  font-weight: 600;
  font-size: rem(15px);
  line-height: 120%;
  border-radius:  rem(16px);

  &.btn-sm {
    min-height: 48px;
  }
  &.btn-md {}
  &.btn-lg {}

  &.btn-primary {
    color: $secondary-color;
    border-color: $primary-color;
    background-color: $primary-color;

    &.disabled {
      color: #AEAEAE;
      border-color: #F0F0F0;
      background-color: #F0F0F0;
      cursor: not-allowed;

      svg {
        path {
          fill: #AEAEAE;
        }
      }
    }
    &:not(.disabled):hover {
      color: #ffffff;
      border-color: $secondary-color;
      background-color: $secondary-color;
    }
  }
  &.btn-secondary {
    color: #ffffff;
    border-color: $secondary-color;
    background-color: $secondary-color;

    &.disabled {
      color: #AEAEAE;
      border-color: #F0F0F0;
      background-color: #F0F0F0;
      cursor: not-allowed;

      svg {
        path {
          fill: #AEAEAE;
        }
      }
    }

    &:not(.disabled):hover {
      color: #ffffff;
      border-color: lighten($secondary-color, 10%);
      background-color: lighten($secondary-color, 10%);
    }
  }
  &.btn-outline-secondary {
    color: $secondary-color;
    border-color: $secondary-color;
    background-color: white;

    &:not(.disabled):hover {
      color: #ffffff !important;
      border-color: $secondary-color !important;
      background-color: $secondary-color;
    }
  }
  &.btn-outline-t-secondary {
    color: $secondary-color;
    border-color: $secondary-color;
    background-color: transparent;

    &:not(.disabled):hover {
      color: #ffffff !important;
      border-color: $secondary-color !important;
      background-color: $secondary-color;
    }
  }
  &.btn-filter {
    padding: 10px 16px;
    min-height: rem(44px);
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: $secondary-color;
    border-color: #D6D6D6;
    background-color: transparent;

    &:not(.disabled):hover {
      color: $secondary-color;
      border-color: $gray-color;
      background-color: transparent;
    }

    &.btn-link {
      padding: 10px 10px;
      text-decoration: underline;
      border: none !important;

      &:not(.disabled):hover {
        text-decoration: none;
      }
    }
  }
  &.btn-link {
    padding: 10px 14px;
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(18px);
    color: $secondary-color;
  }
}

.btn-social {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-size: 20px;
  color: $secondary-color;
  background-color: rgba(#ffffff, 0.7);
  border: none;
  border-radius: 50%;

  &:hover {
    color: #ffffff;
    background-color: $secondary-color;
  }
}

.checkbox-simple {

  input {
    display: none;

    &:checked + label {
      &:before {
        content: '\f00c';
        background-color: $primary-color;
        border-color: $secondary-color;
      }
    }
  }
  label {
    display: grid;
    grid-template-columns: 20px minmax(0, 1fr);
    column-gap: 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0px;

    a {
      color: $secondary-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
    &:before {
      content: '';
      display: block;
      margin-top: 2px;
      width: 20px;
      height: 20px;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $secondary-color;
      font-family: FontAwesome;
      background-color: #ffffff;
      border: 1px solid #D6D6D6;
      border-radius: 5px;
      transition: all .3s;
    }
  }

  &.is-invalid {
    label {
      &:before {
        border-color: #FF0221;
      }
    }
  }
}

.fields-group {
  .form-group {
    margin-bottom: 0px;
  }
}
.form-group {
  position: relative;
  margin-bottom: 16px;

  &.floating-label {
    label {
      position: absolute;
      top: 50%;
      top: 16px;
      left: 0px;
      padding-left: 16px;
      font-size: 15px;
      line-height: 24px;
      color: $gray-color;
      //transform: translateY(-50%);
      transform-origin: left;
      transition: all 0.2s;
      pointer-events: none;
    }
    input[type="email"], input[type="text"], textarea {
      &.form-control {
        padding: 25px 15px 5px 15px;
        height: 56px;
        color: $secondary-color;
        border-radius: 12px;
        border-color: #D6D6D6;
        box-shadow: 0px 0px 0px 0px $secondary-color;

        &::placeholder {
          display: none;
          opacity: 0;
        }
        &:hover {
          border-color: $gray-color;
        }
        &:focus, &:not(:placeholder-shown) {
          border-color: $secondary-color;
          box-shadow: 0px 0px 0px 0.5px $secondary-color;

          & + label {
            top: 3px;
            transform: scale(0.85);
          }
        }
      }
    }
    textarea {
      &.form-control {
        min-height: 160px;
        height: initial;
      }
    }
    .error-message {
      display: none;
      padding: 0px 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0px;
      color: #FF0221;
    }
    label:has(+ .form-control:focus), label:has( + .form-control:not(:placeholder-shown)) {
      top: 3px;
      transform: scale(0.85);
    }
    &.is-invalid {
      input[type="email"], input[type="text"], textarea {
        &.form-control {
          border-color: #FF0221;
          box-shadow: none;
        }
      }
      .error-message {
        display: block;
      }
    }
  }
}

input[type="email"], input[type="text"] {
  &.form-control {

  }
}

.dropdown.bootstrap-select {
  > .dropdown-toggle {
    position: relative;
    padding: rem(6px) rem(40px) rem(6px) rem(16px);
    font-weight: 400;
    font-size: rem(16px);
    border: 1px solid #D6D6D6;
    border-radius: 12px;

    &:after {
      content: "\f078";
      position: absolute;
      top: 50%;
      right: 16px;
      margin: 0px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-family: 'FontAwesome';
      border: none;
      transform: translateY(-50%);
    }
    &:hover, &:focus {
      border-color: $gray-color;
      outline: none !important;
      background-color: #ffffff;
    }
    &.show {
      border-color: $secondary-color;
      box-shadow: 0px 0px 0px 0.5px $secondary-color;
    }
  }
  > .dropdown-menu {
    margin: 8px 0px !important;
    padding: 8px;
    border-radius: 24px;
    box-shadow: 0px 2px 8px 0px #00000026;

    .dropdown-menu.inner {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 2px;

      li {
        .dropdown-item {
          padding: 9px 16px;
          font-size: rem(16px);
          line-height: rem(24px);
          color: $secondary-color;

          &:hover, &:focus, &.active {
            background-color: $violet-light-color;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

.form-check {
  margin-bottom: rem(16px);
  padding-left: rem(30px);
  font-size: rem(15px);
  line-height: rem(24px);
  color: $secondary-color;

  .form-check-input {
    position: relative;
    margin-left: -30px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border-color: $gray-color;
    cursor: pointer;

    &:checked, &[checked="checked"] {
      background-color: $primary-color;
      border-color: $secondary-color;
      background-image: none !important;

      &[type="checkbox"] {
        &:before {
          content: '';
          position: absolute;
          top: 2px;
          left: 6px;
          display: block;
          height: 10px;
          width: 6px;
          border-right: 2px solid $secondary-color;
          border-bottom: 2px solid $secondary-color;
          transform: rotate(45deg);
        }
      }
    }
  }
  .form-check-label {
    cursor: pointer;
  }
  &:hover {
    .form-check-input {
      border-color: $secondary-color;
    }
  }
}
.filter-check {
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  padding-left: 0px;

  input {
    display: none;
  }
  label {
    display: flex;
    column-gap: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;

    &:before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2px;
      width: 20px;
      height: 20px;
      font-family: FontAwesome;
      border-radius: 5px;
      background-color: #ffffff;
      border: 1px solid $gray-color;
      transition: all .3s;
    }
    &:has(input:checked) {
      &:before {
        content: "\f00c";
        background-color: $primary-color;
        border-color: $secondary-color;
      }
    }
  }
  .childs-indicator {
    padding: 0px 10px;
  }
}

ul {
  padding-left: 22px;
}
.list-wt-round-icons {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 6px;

  .list-el {
    display: grid;
    grid-template-columns: minmax(0, 36px) minmax(0, 1fr);
    column-gap: 10px;
    align-items: center;

    i {
      display: grid;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      font-size: 20px;
      background-color: $violet-light-color;
      border-radius: 18px;
    }
    span {
      font-size: 15px;
      line-height: 24px;
    }
  }

  @include media-breakpoint-up(lg) {
    row-gap: 12px;
  }
}
.round-checkmark-list {
  list-style: none;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;
  padding: 0px;

  li {
    position: relative;
    padding-left: 32px;

    &:before {
      content: '\f00c';
      position: absolute;
      top: -2px;
      left: 0px;
      display: block;
      width: 22px;
      height: 22px;
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      font-family: FontAwesome;
      background-color: $violet-color;
      border-radius: 11px;
    }
  }
  &.text-small {
    li {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
    }
  }
  &.type-2 {
    li {
      padding-left: 36px;

      &:before {
        top: 0px;
        width: 24px;
        height: 24px;
      }
    }
  }
}