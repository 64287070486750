@import "components/header";
@import "components/menu";
@import "components/search";
@import "components/content_grid";

:root {
  --drawer-enter-transition-curve: transform 195ms cubic-bezier(0.4,0,1,1), opacity 195ms cubic-bezier(0.4,0,1,1);
  --drawer-exit-transition-curve: transform 225ms cubic-bezier(0,0,0.2,1), opacity 225ms cubic-bezier(0,0,0.2,1);
  --drawer-transform-bottom-out: translate3d(0, 100%, 0);
  --drawer-transform-bottom-in: translate3d(0, 0, 0);
  --drawer-transform-left-out: translate3d(-100%, 0, 0);
  --drawer-transform-left-in: translate3d(0, 0, 0);
  --drawer-transform-right-out: translate3d(100%, 0, 0);
  --drawer-transform-right-in: translate3d(0, 0, 0);
}

body {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.2px;
  color: $secondary-color;

  &.product-gallery-active, &.locked {
    overflow: hidden;
    padding-right: 0px;
  }
}
.page-wrapper {
  position: relative;
  padding-top: 208px;

  #content_wrapper {
    //min-height: 100%;
  }
  &.content_layout_simple {

  }
  &.content_layout_checkout {
    padding-top: 0px;
  }
  &[data-content-type="products/detailed"],
  &[data-content-type="news/listing.tpl"], &[data-content-type="news/detailed.tpl"],
  &[data-content-type="pages/contacts"], &[data-content-type="pages/editor_detailed"] {
    padding-top: 82px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 271px;

    &[data-content-type="products/detailed"],
    &[data-content-type="news/listing.tpl"], &[data-content-type="news/detailed.tpl"],
    &[data-content-type="pages/contacts"],&[data-content-type="pages/editor_detailed"] {
      padding-top: 164px;
    }
  }
}
#customers_authorize {
  max-width: 458px;
}
footer.footer {
  padding-top: 50px;
  padding-bottom: 40px;
  background-color: #B5E0FF;

  .content-wide {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 20px;

    .top-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 32px;

      .left-block {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        align-content: start;
        row-gap: 24px;

        .logo-socials-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          justify-items: start;
          row-gap: 37px;

          .footer-logo-wrp {

          }
          .footer-socials-wrp {
            display: flex;
            column-gap: 12px;
            row-gap: 12px;
          }
        }
        #pages_footer_menu {
          .menu-column {
            .column-title {
              display: flex;
              align-items: center;
              column-gap: 6px;
              margin: 0px;
              padding: 17px 0px;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: $secondary-color;

              i {
                width: 36px;
                font-size: 20px;
                text-align: center;

                &.indicator {
                  width: 26px;
                  font-size: 16px;
                  transition: all .3s;
                }
              }
              span {
                flex-grow: 1;
              }
            }
            .column-items {
              ul {
                list-style: none;
                display: grid;
                grid-template-columns: minmax(0, 1fr);
                row-gap: 15px;
                margin: 0px;
                padding: 6px 0px 16px 42px;

                li {
                  a {
                    font-size: 15px;
                    line-height: 24px;
                    color: $secondary-color;
                    text-decoration: none;

                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
            &.has-items {
              .column-title {
                cursor: pointer;
              }
            }
          }
        }
      }
      .right-block {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 8px;

        #subscribers_subscribe {
          .btn-subscribe-open {
            display: grid;
            grid-template-columns: minmax(0, 1fr) auto;
            align-items: center;
            column-gap: 20px;
            padding: 12px 12px 12px 20px;
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: left;
            color: $secondary-color;
            background-color: #FFFFFF;
            border: none;
            border-radius: 16px;

            .icon-wrp {
              display: grid;
              align-items: center;
              justify-content: center;
              width: 48px;
              height: 48px;
              font-size: 20px;
              background-color: $primary-color;
              border-radius: 16px;
            }
          }
          .footer-newsletter {
            display: none;
            padding: 32px;
            background-color: #ffffff;
            border-radius: 24px;

            .newsletter-form-wrp {
              .fields-wrp {
                grid-template-areas: "input button"
                                      "checkbox checkbox";
                grid-template-columns: minmax(0, 1fr) auto;
                column-gap: 8px;

                .form-group {
                  grid-area: input;
                }
                .form-check {
                  grid-area: checkbox;
                  margin-bottom: 0px;
                }
                .btn {
                  grid-area: button;
                  margin-top: 0px;
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
        .help-block {
          display: none;
        }
      }
    }
    .bottom-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 20px;

      .left-block {
        text-align: center;

        .inquiry-link { // not btn, because on desktop it's just a link
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 36px;
          min-height: 56px;
          width: 100%;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: $secondary-color;
          border: 1px solid $secondary-color;
          border-radius: 16px;

          i {
            margin-left: 10px;
            font-size: 20px;
          }
        }
        .privacy-link {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $secondary-color;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
      .right-block {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        justify-items: center;
        text-align: center;
        row-gap: 16px;


        .copyright {
          small {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $gray-color;
          }
        }
        .credits-wrp {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          gap: 24px;

          .credits-item {
            font-size: 14px;
            line-height: 18px;

            a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .content-wide {
      .top-wrp {
        .left-block {
          #pages_footer_menu {
            .menu-column {
              &.has-items {
                .column-items {
                  display: grid;
                  grid-template-rows: 0fr;
                  transition: grid-template-rows 0.3s;

                  > div {
                    overflow: hidden;
                  }
                }
                &.open {
                  .column-title {
                    i {
                      &.indicator {
                        transform: rotate(180deg);
                      }
                    }
                  }
                  .column-items {
                    grid-template-rows: 1fr;
                  }
                }
              }
            }
          }
        }
        .right-block {}
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding-top: 80px;

    .content-wide {
      .top-wrp {
        .left-block {
          .logo-socials-wrp {
            grid-template-columns: auto minmax(0, 1fr);
            column-gap: 47px;

            .footer-logo-wrp {
              img {
                width: 163px;
              }
            }
          }
          #pages_footer_menu {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            column-gap: 40px;

            .menu-column {
              .column-title {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;

                i {
                  display: none;
                }
              }
              .column-items {
                ul {
                  padding: 0px;
                }
              }
            }
          }
        }
        .right-block {

        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .content-wide {
      row-gap: 65px;

      .top-wrp {
        grid-template-columns: minmax(0, 5fr) minmax(0, 4fr);
        column-gap: 100px;

        .right-block {
          #subscribers_subscribe {
            .btn-subscribe-open {
              display: none;
            }
            .footer-newsletter {
              display: block;
            }
          }
          .help-block {
            display: block;
          }
        }
      }
      .bottom-wrp {
        grid-template-columns: auto minmax(0, 1fr);
        align-items: center;
        column-gap: 40px;

        .left-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 32px;

          .inquiry-link {
            margin-bottom: 0px;
            min-height: auto;
            text-decoration: underline;
            border: none;

            &:hover {
              text-decoration: none;
            }
          }
          .privacy-link {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            white-space: nowrap;
          }
        }
        .right-block {
          display: flex;
          justify-content: end;
          align-items: center;
          column-gap: 32px;

          .copyright {
            flex-shrink: 0;

            small {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .credits-wrp {
            justify-content: end;
            column-gap: 30px;

            .credits-item {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.backdrop {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99999;
  display: none;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  animation: FadeOutBlock 225ms ease-in-out;

  &.menu-desk-backdrop {
    --top-padding: 116px;

    top: var(--top-padding);
    z-index: initial;
    height: calc(100vh - var(--top-padding));
  }
  &.active {
    display: block;
    opacity: 1;
    animation: FadeInBlock 195ms ease-in-out;
  }
}