#old {
  .page_editor_template {
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1260px;

    &.full_width {
      max-width: 100%;
      padding-left: 0px;
      padding-right: 0px;

      .row, .product_listing {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 78px;
    }
  }
  .page_editor_banner_right {
    > div {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;

      .modal-body & {
        flex-direction: column;
      }
    }
  }
  .page_editor_banner_top {
    @include media-breakpoint-down(sm) {
      > div {
        &.banner-wrp {
          order: 1;
        }
        &.texts-wrp {
          order: 2;
        }
      }
    }
  }
  .page_editor_banner_bottom {
    @include media-breakpoint-down(sm) {
      > div {
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 1;
        }
      }
    }
  }

  .editor_type_text {
    &.editor_type_title_block {
      font-weight: 300;

      h1, .h1 {
        margin-bottom: 14px;
      }
    }
    @include media-breakpoint-down(md) {
      h2, .h2, h3, .h3 {
        text-align: left;
      }
    }
    @include media-breakpoint-up(md) {
      &.editor_type_title_block {
        h1, .h1 {
          margin-bottom: 19px;
        }
      }
    }
  }
  .editor_type_banners_slider {
    .text-wrp {
      .h1, h2, p {
        color: inherit;
      }
    }
    @include media-breakpoint-up(md) {
      .banner-wrp {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        height: inherit;

        img {
          max-width: initial;
          height: inherit;
          grid-column-start: 2;
          grid-column-end: 3;
        }
        &.banner-left {
          direction: rtl;

          img {
            margin-right: auto;
          }
        }
        &.banner-right {}
        &.banner-center {
          //grid-template-columns: minmax(0, 0.5fr) minmax(0, 1fr) minmax(0, 0.5fr);
          grid-template-columns: minmax(0, 1fr);
          justify-items: center;

          img {
            grid-column-start: 1;
            grid-column-end: 2;
            //object-fit: cover;
            //width: 100%;
            //height: 100%;
          }
        }

        &.text-left {
          img {
            margin-left: auto;
          }
        }
        &.text-right {
          direction: rtl;

          img {
            margin-right: auto;
          }
        }
      }
      .text-outer-wrp {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding: 40px 0px;

        .text-wrp {
          &.content-right {
            grid-column-start: 2;
            grid-column-end: 3;
            text-align: right;
          }
          &.content-center {
            max-width: 600px;
            text-align: center;
          }
        }
        &.no-columns {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          justify-items: center;
        }
      }
    }
  }
  .editor_type_banner {
    margin-bottom: 61px;
    padding: 0px;

    a {
      &:hover {
        text-decoration: none;
      }
    }
    .banner-inner-wrp {
      display: flex;
      align-items: center;
      padding: 28px;
      min-height: 222px;
      background-size: cover;
      background-position: left center;
      background-repeat: no-repeat;

      .banner-content-wrp {
        max-width: 70%;

        h2 {
          margin: 0px;
          margin-bottom: 10px;
          line-height: 30px;
          text-transform: initial;
          letter-spacing: 0.326068px;
        }
        p {
          margin-bottom: 12px;
        }
        button {
          margin-bottom: 15px;
          padding: 7px 0px;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.13em;
          color: #000000;
          background-color: transparent;
          border: none;
          box-shadow: inset 0 0.15em transparent, inset 0 -0.15em #000000;
        }
        &.fonts-1 {
          h2 {
            margin-bottom: 5px;
            font-size: 28px;
            line-height: 30px;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }

      &.ha-left {
        justify-content: flex-start;
      }
      &.ha-center {
        justify-content: center;
      }
      &.ha-right {
        justify-content: flex-end;
      }
    }
    &.ha-left {
      .banner-inner-wrp {
        background-position: left center;
      }
    }
    &.ha-center {
      .banner-inner-wrp {
        background-position: center;
      }
    }
    &.ha-right {
      .banner-inner-wrp {
        background-position: right center;
      }
    }
    &.full_width {
      padding: 0px;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 55px;

      .banner-inner-wrp {
        padding: 70px;
        min-height: 540px;

        .banner-content-wrp {
          h2 {
            margin-bottom: 18px;
            font-size: 70px;
            line-height: 74px;
            letter-spacing: 0.793605px;
          }
          p {
            margin-bottom: 20px;
            font-weight: 300;
            font-size: 17px;
            line-height: 28px;
          }
          button {
            margin-bottom: 0px;
            padding: 4px 0px;
            font-size: 13px;
            line-height: 22px;
          }
          &.fonts-1 {
            h2 {
              margin-bottom: 19px;
              font-size: 50px;
              line-height: 48px;
              letter-spacing: 0px;
            }
            p {
              font-size: 14px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
  .editor_type_banner_text  {
    .inner-wrp {
      flex-direction: column;

      > div {
        width: 100%;
      }
      .banner-wrp {
        height: 290px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &.shadow {
          box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3) !important;
        }
      }
      .texts-wrp {
        padding: 42px 32px;
        text-align: center;

        h2 {
          margin-top: 0px;
          margin-bottom: 23px;
          text-transform: initial;
          color: inherit;
        }
        .text-wrp {
          margin-bottom: 24px;
        }
        &.fonts-1 {
          line-height: 22px;
          font-size: 15px;
        }
        &.margin-tb {
          margin: 0px 8px;
          width: calc(100% - 16px);
        }
      }
    }
    @include media-breakpoint-down(md) {
      padding-left: 14px;
      padding-right: 14px;
    }
    @include media-breakpoint-up(md) {
      .inner-wrp {
        flex-direction: row;

        > div {
          width: 50%;
        }
        .banner-wrp {
          height: auto;

          &.wide {
            width: 58.3%;
          }
        }
        .texts-wrp {
          padding: 142px 32px;
          text-align: left;

          .text-column {
            max-width: 390px;
          }
          .text-wrp {
            margin-bottom: 30px;
          }
          &.fonts-1 {
            line-height: 30px;
            font-size: 17px;
          }
          &.margin-tb {
            margin: 28px 0px;
            width: 41.7%;
          }
        }
      }
    }
  }

  .editor_type_product_banner {
    .photo-wrp {}
    .content-wrp {
      .block-subtitle {
        color: inherit;
      }
      .block-title {
        color: inherit;
      }
    }
  }

  .editor_type_features  {
    overflow: hidden;
    margin-bottom: 25px;
    padding: 40px 15px;

    .block-title, .feature-title {
      color: inherit;
    }
    .inner-wrp {
      display: flex;
      flex-direction: column;
      align-items: center;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 28px 28px 30px 28px;
        min-height: 260px;
        width: calc(100% - 20px);
        max-width: 350px;
        text-align: center;
        background-color: #F9F9FB;
        transition: all .3s;

        .img-wrp {
          margin-bottom: 10px;

          img {
            margin: 0px auto;
            height: 40px;
            width: auto;
          }
        }
        h3 {
          margin-bottom: 0px;
          font-weight: normal;
          font-size: 20px;
          line-height: 23px;
        }
        p {
          margin-bottom: 0px;
          font-size: 14.189px;
          line-height: 18px;
        }
        &.active {
          z-index: 1;
          max-width: 370px;
          min-height: 300px;
          width: 100%;
          background-color: #FFFFFF;
          box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
        }
      }
    }
    &.style_1 {
      margin-top: -39px;
    }
    @include media-breakpoint-up(lg) {
      padding: 40px 0px;

      .inner-wrp {
        flex-direction: row;

        .item {
          min-height: 390px;
          max-width: initial;
          width: calc(100% / 3);

          .img-wrp {
            margin-bottom: 21px;

            img {
              height: 68px;
            }
          }
          h3 {
            margin-bottom: 3px;
            font-size: 22px;
            line-height: 26px;
          }
          p {
            font-size: 17px;
            line-height: 22px;
          }
          &.active {
            max-width: initial;
            min-height: 416px;
            width: calc(100% / 3);
          }
        }
      }
    }
  }

  .editor_type_banner_banner, .editor_type_banners.style_1 {
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .inner-wrp {
      display: grid;
      flex-direction: column;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      .banner-wrp {
        .banner-inner-wrp {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 28px;
          min-height: 280px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          h3 {
            margin: 0px 0px 12px 0px;
            font-weight: normal;
            font-size: 28px;
            line-height: 24px;
            color: inherit;
          }
        }
      }
      &.wt_gap {
        gap: 25px;
      }
    }
    @include media-breakpoint-up(md) {
      .inner-wrp {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .banner-wrp {
          .banner-inner-wrp {
            min-height: 564px;
          }
        }
      }
    }
  }

  .editor_type_texts_banner {
    .inner-wrp {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto;

      .banner-wrp {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        position: relative;
        overflow: hidden;
        margin: -40px 27px 0px 27px;
        min-height: 290px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: initial;
          min-height: 100%;
          min-width: 100%;
          width: 100%;
          transform: translate(-50%, -50%);
        }
      }
      .text-wrp {
        &.text-top-wrp {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          padding: 45px 28px 75px 28px;
          background-color: #F9F9FB;

          h1 {
            margin-top: 0px;
            margin-bottom: 9px;
          }
          p {
            line-height: 20px;
          }
        }
        &.text-bottom-wrp {
          grid-column: 1 / 2;
          grid-row: 3 / 4;
          padding: 54px 28px 32px 28px;
        }
      }
    }
    @include media-breakpoint-up(md) {
      .inner-wrp {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto;

        .banner-wrp {
          grid-column: 2 / 3;
          grid-row: 1 / 3;
          margin: 0px;

          img {
            width: auto;
          }
        }
        .text-wrp {
          &.text-top-wrp {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            padding: 96px 69px 79px 69px;

            .inner-text-wrp {
              max-width: 380px;
            }
            h1 {
              margin-bottom: 19px;
            }
            p {
              line-height: 26px;
            }
          }
          &.text-bottom-wrp {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            padding: 101px 69px 75px 69px;

            .inner-text-wrp {
              max-width: 500px;
            }
          }
        }
      }
    }
  }

  .editor_type_brands {
    .brands-list-wrp {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 30px;
      column-gap: 30px;

      .brand-item {
        display: grid;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
      }
    }
    .tns-carousel {
      display: flex !important;

      .tns-item {
        .brand-item {
          display: grid;
          align-items: center;
          justify-content: center;
          height: 100%;
          background-color: #ffffff;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .brands-list-wrp {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
    @include media-breakpoint-up(lg) {
      .brands-list-wrp {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }

  .editor_type_news {
    .tns-carousel-inner {
      display: flex !important;

      .tns-item {
        .card {
          height: 100%;

          .card-body {
            display: grid;
            grid-template-rows: auto minmax(0, 1fr) auto;
            row-gap: 12px;

            .blog-entry-title {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

.page-editor-element {
  margin-bottom: 80px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 95px;
  }
}

.page-editor-type-travel_search {
  margin-bottom: 24px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 48px;
  }
}

.page-editor-type-text {
  > div {
    p {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.page-editor-type-collections_carousel {
  margin-bottom: 32px;
  overflow: hidden;

  .swiper {
    overflow: visible;

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: grid;
        column-gap: 8px;
        grid-template-columns: repeat(2, minmax(0, 100fr)) minmax(0, 25fr);

        @media (min-width: 440px) {
          grid-template-columns: repeat(3, minmax(0, 100fr)) minmax(0, 25fr);
        }
        @media (min-width: 600px) {
          grid-template-columns: repeat(4, minmax(0, 100fr)) minmax(0, 25fr);
        }
        @media (min-width: 900px) {
          column-gap: 7px;
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }
      }
    }
  }
  .collection-item {
    position: relative;
    overflow: hidden;
    border-radius: 14px;

    img {
      z-index: 1;
      width: 100%;
      transition: all .3s;
    }
    .texts-wrp {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      padding: 16px;
      color: $secondary-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      span {}
    }

    &:hover {
      img {
        transform: scale(1.04);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;

    .collection-item {
      .texts-wrp {
        padding: 20px;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}

.page-editor-type-collection {
  overflow: hidden;

  .swiper {
    overflow: visible;
    width: 100%;

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        --column-gap: 12px;
        display: flex;
        column-gap: var(--column-gap);

        .swiper-slide {
          width: calc(85% - (var(--column-gap) * 3) / 4) !important;

          .photo-wrp {
            .swiper {
              &:not(.swiper-initialized) {
                .swiper-wrapper {
                  .swiper-slide {
                    flex-shrink: 0;
                    width: 100% !important;
                  }
                }
                .swiper-nav-btn, .swiper-pagination {
                  display: none !important;
                }
              }
            }
          }
        }

        @media (min-width: 560px) {
          .swiper-slide {
            width: calc(45% - (var(--column-gap) * 3) / 4) !important;
          }
        }
        @media (min-width: 767px) {
          .swiper-slide {
            width: calc(31.25% - (var(--column-gap) * 3) / 4) !important;
          }
        }
        @media (min-width: 992px) {
          --column-gap: 16px;

          .swiper-slide {
            width: calc(25% - (var(--column-gap) * 3) / 4) !important;
          }
        }
      }
    }
  }
}

.page-editor-type-banner_simple {
  img {
    border-radius: 14px;
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    img {
      border-radius: 24px;
    }
  }
}

.page-editor-type-banner_hero {
  grid-column: full-width !important;
  display: grid;
  align-items: end;
  justify-content: start;
  min-height: 460px;
  color: #ffffff;
  background-color: $gray-color;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .inner-wrp {
    padding: 48px 16px;

    .texts-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      justify-items: start;
      max-width: 360px;

      .title {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 36px;
        line-height: 36px;
        font-family: Borna;
      }
      .desc {
        font-size: 16px;
        line-height: 25px;

        p {
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    grid-column: content-wide !important;
    min-height: 500px;
    border-radius: 24px;

    .inner-wrp {
      padding: 56px;

      .texts-wrp {
        .title {
          margin-bottom: 16px;
          font-size: 40px;
          line-height: 48px;
        }
        .desc {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}

.page-editor-type-banner_text {
  .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 40px;

    .banner-wrp {
      order: 2;

      img {
        width: 100%;
        border-radius: 24px;
      }
    }
    .texts-wrp {
      order: 1;

      .title {
        font-size: 24px;
        line-height: 27px;
        letter-spacing: -0.4px;
      }
      .desc {
        font-size: 15px;
        line-height: 24px;

        p {
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    &.banner-top {
      .banner-wrp { order: 1; }
      .texts-wrp { order: 2; }
    }
  }

  @include media-breakpoint-up(lg) {
    .inner-wrp {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 40px;
      align-items: center;

      .banner-wrp { order: 1; }
      .texts-wrp {
        order: 2;
        padding: 0px 20px 0px 0px;

        .title {
          font-size: 28px;
          line-height: 32px;
        }
        .desc {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &.banner-right {
        .banner-wrp { order: 2; }
        .texts-wrp {
          order: 1;
          padding: 0px 0px 0px 20px;
        }
      }
    }
  }
}
.page-editor-type-banner_banner {
  overflow: hidden;

  .swiper {
    overflow: visible;
    width: 100%;

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        --column-gap: 16px;
        display: flex;
        column-gap: var(--column-gap);

        .swiper-slide {
          width: 100% !important;
        }
        @include media-breakpoint-up(md) {
          .swiper-slide {
            width: calc(50% - (var(--column-gap) / 2)) !important;
          }
        }
      }
    }
    .swiper-pagination {
      position: initial;
      margin-top: 16px;
    }
  }
  a {
    color: $secondary-color;

    &:hover {
      text-decoration: none;
    }
  }
  .inner-wrp {
    .banner-wrp {
      position: relative;
      overflow: hidden;
      display: block;
      border-radius: 24px;

      > img {
        width: 100%;
        transition: all .3s;
      }
      .banner-inner-wrp {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;

        h3 {
          margin: 0px 0px 24px 0px;
          //font-weight: normal;
          //font-size: 28px;
          //line-height: 24px;
          color: inherit;
        }
        .btn {
          width: 100%;
        }
        &.ha-left { align-items: start; }
        &.ha-right { align-items: end; }
        &.va-start { justify-content: start; }
        &.va-end { justify-content: end; }
      }
      &:hover {
        > img {
          transform: scale(1.02);
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .inner-wrp {
      .banner-wrp {
        .banner-inner-wrp {
          padding: 40px;

          .btn {
            width: auto;
            min-width: 180px;
          }
        }
      }
    }
  }
}
.page-editor-type-banners {
  overflow: hidden;

  .swiper {
    overflow: visible;

    .swiper-pagination {
      position: initial;
      margin-top: 16px;
    }
    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: grid;
        row-gap: 16px;
        column-gap: 16px;
        grid-template-columns: minmax(0, 1fr);
      }
    }
  }

  .swiper-slide {
    overflow: hidden;
    border-radius: 24px;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    .swiper {
      .swiper-pagination {
        display: none;
      }
      &:not(.swiper-initialized) {
        .swiper-wrapper {
          grid-template-columns: minmax(0, 824fr) minmax(0, 400fr);

          .swiper-slide {
            &:first-child {
              grid-row: span 2;
            }
          }
        }
        &.single-banner {
          .swiper-wrapper {
            grid-template-columns: minmax(0, 1fr);

            .swiper-slide {
              margin-left: auto;
              margin-right: auto;
              max-width: 824px;
            }
          }
        }
      }
    }
  }
}

.page-editor-type-promo_small {
  container-name: promo-small;
  container-type: inline-size;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  max-width: 610px;
  width: 100%;
  background-color: #B5E0FF;
  border-radius: 24px;

  .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 16px;

    .photo-wrp {
      img {
        max-width: 108px;
        width: 100%;
        border-radius: 14px;
      }
    }
    .texts-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      justify-items: start;
      row-gap: 24px;

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0px;
      }
      .btn {
        text-decoration: none !important;
      }
    }
  }
  @container promo-small (width > 450px) {
    .inner-wrp {
      grid-template-columns: 158px minmax(0, 1fr);
      column-gap: 24px;

      .photo-wrp {
        img {
          max-width: 100%;
        }
      }
      .texts-wrp {
        .title {
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
        }
      }
    }
  }
}

.page-editor-type-pages_menu {
  .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    .block-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px;
    }
    .pages-menu-wrp {
      list-style: none;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 8px;
      margin: 0px;
      padding: 0px;

      li {
        a {
          display: grid;
          grid-template-columns: minmax(0, 1fr) auto;
          align-items: center;
          column-gap: 16px;
          padding: 12px;
          min-height: 70px;
          background-color: $violet-light-color;
          border-radius: 14px;

          .left-wrp {
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon-wrp {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 46px;
              height: 46px;
              background-color: $violet-color;
              border-radius: 10px;
            }
            .name {
              margin-left: 12px;
              font-weight: 600;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0px;
            }
          }
          > i {
            width: 24px;
            text-align: center;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .inner-wrp {
      .pages-menu-wrp {
        li {
          a {
            .left-wrp {
              .name {
                margin-left: 16px;
                font-size: 18px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }
}

.page-editor-type-features {
  .block-title {
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 27px;
  }
  &.style_1 {
    .features-groups-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 12px;

      .features-group-list {
        list-style: none;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 12px;
        columns: 1;
        margin: 0px;
        padding: 0px;

        .feature-item {
          display: flex;
          align-items: center;
          column-gap: 10px;

          .img-wrp {
            flex-shrink: 0;
            display: grid;
            align-items: center;
            justify-content: center;
            padding: 6px;
            width: 36px;
            height: 36px;
            border-radius: 18px;
            background-color: $violet-light-color;
          }
          p {
            margin: 0px;
          }
        }
      }
    }
  }
  &.style_2 {
    .inner-wrp {
      padding: 24px 16px;
      background-color: #B5E0FF;
      border-radius: 24px;

      .block-title {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0px;
        font-family: Inter;
      }
      .features-groups-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 10px;

        .features-group-list {
          list-style: none;
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 10px;
          margin: 0px;
          padding: 0px;

          .feature-item {
            display: flex;
            flex-direction: row;
            column-gap: 14px;

            .img-wrp {
              flex-shrink: 0;
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              align-items: center;
              justify-content: center;
              padding: 5px;
              width: 64px;
              height: 64px;
              background-color: #ffffff;
              border-radius: 16px;

              img {
                object-fit: contain;
              }
            }
            .texts-wrp {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              align-items: center;
              align-content: center;

              .title {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0px;
              }
              .desc {
                margin: 0px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0px;
                color: $gray-color;
              }
            }
          }
        }
      }
    }
  }
  &.style_3 {
    .block-title {
      margin-bottom: 20px;
    }
    .features-outer-wrp {
      padding: 16px;
      background-color: #B5E0FF;
      border-radius: 24px;

      .features-list {
        list-style: none;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 20px;
        margin: 0px;
        padding: 0px;

        .feature-item {
          display: flex;
          align-content: start;
          column-gap: 12px;

          .photo-wrp {
            flex-shrink: 0;
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            justify-items: center;
            align-items: center;
            padding: 5px;
            width: 46px;
            height: 46px;
            background-color: #E3FF8C;
            border-radius: 10px;

            img {
              max-height: 24px;
            }
          }
          .texts-wrp {
            display: flex;
            align-items: center;
            min-height: 46px;

            .feature-desc {
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0px;
            }
          }
        }
      }
    }
  }
  &.style_4 {
    .features-list {
      list-style: none;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 32px;
      margin: 0px;
      padding: 0px;

      .feature-item {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .texts-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);

          .feature-title {
            font-weight: 600;
            font-size: 44px;
            line-height: 48px;
            letter-spacing: 0px;
            font-family: Borna;
          }
          .feature-desc {
            margin: 0px;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0px;
          }
        }
        .photo-wrp {
          img {
            max-width: 102px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .block-title {
      margin-bottom: 32px;
      max-width: 380px;
      font-size: 28px;
      line-height: 32px;
    }
    &.style_1 {
      .features-groups-wrp {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: start;
        column-gap: 20px;

        .features-group-list {
          row-gap: 20px;
        }
      }
    }
    &.style_2 {
      .inner-wrp {
        padding: 40px;

        .block-title {
          margin-bottom: 24px;
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
        }
        .features-groups-wrp {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 20px;
          align-items: start;

          .features-group-list {
            row-gap: 24px;

            .feature-item {
              .img-wrp {
                padding: 6px;
                width: 72px;
                height: 72px;
              }
            }
          }
        }
      }
    }
    &.style_3 {
      .block-title {
        margin-bottom: 24px;
      }
      .features-outer-wrp {
        padding: 40px;

        .features-list {
          row-gap: 24px;

          .feature-item {
            column-gap: 20px;

            .photo-wrp {
              width: 56px;
              height: 56px;

              img {
                max-height: 28px;
              }
            }
            .texts-wrp {
              min-height: 56px;

              .feature-desc {
                font-size: 18px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
    &.style_4 {
      .features-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 30px;
        row-gap: 30px;

        .feature-item {
          .texts-wrp {
            .feature-title {
              font-size: 48px;
            }
            .feature-desc {
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.page-editor-type-help {
  .help-block {
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;
  }
}