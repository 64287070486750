.destination-options-search-field-wrp {
  .fake-field {
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr);
    column-gap: 8px;
    align-items: center;
    padding: 0px 16px 0px 0px;
    height: 56px;
    border: 1px solid $secondary-color;
    border-radius: 12px;
    box-shadow: 0px 0px 0px 0.5px $secondary-color;

    span {
      padding-left: 16px;

      i {
        font-size: 18px;
      }
    }
    input {
      padding: 0px;
      font-size: 15px;
      line-height: 24px;
      background: transparent;
      border: none;

      &:focus-visible {
        outline: none;
      }
    }
  }
}
.destination-options-wrp {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 32px;
  padding: 0px 16px;

  .options-group {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 20px;

    .group-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
    .group-items-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 20px;

      .group-item {
        .item-name {
          flex-grow: 1;
          position: relative;
          padding-left: 32px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;

          .counter {
            display: inline-block;
            padding-left: 5px;
            color: $gray-color;
          }
          .country {
            display: block;
            margin-top: 2px;
            color: $gray-color;
          }
          &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            display: block;
            width: 20px;
            height: 20px;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: $secondary-color;
            font-family: FontAwesome;
            background-color: #ffffff;
            border: 1px solid #D6D6D6;
            border-radius: 5px;
            transition: all .3s;
          }
          &.active, &.semi-active {
            &:before {
              content: '\f00c';
              background-color: $primary-color;
              border-color: $secondary-color;
            }
          }
          &.semi-active:before {
            content: '\f068';
          }
        }

        .item-main {
          display: flex;
          flex-direction: row;
          column-gap: 12px;

          .childs-indicator {
            flex-shrink: 0;
            padding: 0px 16px;
            font-size: 17px;
            line-height: 20px;
            cursor: pointer;

            i {
              color: $gray-color;
              transition: all .3s;
            }
          }
        }
        .item-childs-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: 0fr;
          padding-left: 32px;
          transition: grid-template-rows .3s;

          .childs-inner-wrp {
            overflow: hidden;
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 12px;

            .item-child {
              &:first-child {
                padding-top: 16px;
              }
            }
          }
        }
        &.open {
          .item-main {
            .childs-indicator {
              i {
                transform: rotate(180deg);
                color: $secondary-color;
              }
            }
          }
          .item-childs-wrp {
            grid-template-rows: 1fr;
          }
        }
      }
    }
  }
}

.dates-options-wrp {
  padding-top: 16px;

  .date-type-tabs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 4px;
    margin-bottom: 16px;
    padding: 4px;
    background-color: $violet-light-color;
    border-radius: 12px;

    span {
      padding: 6px 10px;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      border-radius: 12px;
      transition: all .3s;
      cursor: pointer;

      &.active {
        font-weight: 500;
        background-color: #ffffff;
        box-shadow: 0px 5.08px 16.25px -2.03px #18274B1A;
        box-shadow: 0px 3.05px 7.11px -3.05px #4D437824;

      }
    }
  }
  .select-by-date-wrp {
    .calendar-wrp {
      overflow: auto;
      padding: 8px 0px;
      //max-height: 400px;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      .dp__menu {
        border: none;

        .dp__menu_inner {
          row-gap: 32px;
          padding: 0px;

          .dp__instance_calendar {
            > .dp--header-wrap {
              margin-bottom: 14px;

              .dp__month_year_wrap {
                .custom-month-year-component {
                  display: flex;
                  justify-content: center;
                  column-gap: 4px;
                  width: 100%;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 20px;
                }
              }
            }
            > .dp__calendar {
              .dp__calendar_header {
                .dp__calendar_header_item {
                  height: 26px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  color: $gray-color;
                  text-transform: capitalize;
                }
              }
              .dp__calendar_header_separator {
                display: none;
              }
              .dp__calendar {
                --cell-between-color: #CDC4FA;
                //--cell-between-color: purple;

                .dp__calendar_row {
                  display: grid;
                  grid-template-columns: repeat(7, minmax(0, 1fr));

                  .dp__calendar_item {
                    aspect-ratio: 1;

                    .dp__cell_inner {
                      height: 100%;
                      width: 100%;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 24px;
                      color: $secondary-color;

                      &.dp--past {
                        color: #D6D6D6;
                      }
                      &.dp__today {
                        border: none;
                      }
                      &.dp__cell_offset {
                        display: none;
                      }
                      &.dp__range_start, &.dp__range_end, &.dp__active_date {
                        position: relative;
                        z-index: 1;
                        font-weight: 600;
                        color: #ffffff;
                        background-color: $secondary-color;
                        border: none;
                        border-radius: 100%;
                      }
                      &.dp__range_start {}
                      &.dp__range_between {
                        background-color: var(--cell-between-color);
                        border: none;
                      }
                      &.dp__range_end {}
                    }

                    // connect range
                    &[aria-pressed="true"] + .dp__calendar_item .dp__cell_inner.dp__range_between,
                    &:has(+ .dp__calendar_item .dp__cell_inner.dp__range_end:not(.dp__range_start)) .dp__cell_inner {
                      position: relative;

                      &:before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        right: 100%;
                        display: block;
                        width: 50%;
                        height: 100%;
                        background-color: var(--cell-between-color);
                      }
                    }
                    &:has(+ .dp__calendar_item .dp__cell_inner.dp__range_end) .dp__cell_inner:before {
                      right: initial;
                      left: 100%;
                    }

                    // round range row items
                    &:last-child, &:has(+ .dp__calendar_item .dp__cell_inner.dp__cell_offset) {
                      .dp__cell_inner {
                        &.dp__range_between {
                          border-radius: 0px 12px 12px 0px;
                        }
                      }
                    }
                    &:first-child, &:has(.dp__cell_inner.dp__cell_offset) + .dp__calendar_item {
                      .dp__cell_inner {
                        &.dp__range_between {
                          border-radius: 12px 0px 0px 12px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .flex-days-wrp {
      margin: 0px -20px;
      padding: 12px 0px 0px 0px;
      border-top: 1px solid #D6D6D6;

      .custom-swiper-wrp {
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        padding: 0px 20px;
        overflow-x: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        .flex-days-item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 10px;
          min-height: 40px;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          white-space: nowrap;
          background-color: $violet-light-color;
          border-radius: 12px;
          transition: all .3s;

          &:hover {
            background-color: $violet-medium-color;
          }
          &.active {
            background-color: $violet-color;
          }
        }
      }
    }
  }
  .select-by-month-wrp {
    padding-top: 8px;

    .year-groups-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 28px;

      .year-group {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 8px;

        .year-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .months-wrp {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          gap: 6px;

          .month-item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            min-height: 48px;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            text-transform: capitalize;
            background-color: $violet-light-color;
            border-radius: 12px;
            transition: all .3s;
            cursor: pointer;

            &:hover {
              background-color: $violet-medium-color;
            }
            &.active {
              background-color: $violet-color;
            }
            &.month-range-start, &.month-range-end {
              position: relative;
              z-index: 1;
              color: #ffffff;
              background-color: $secondary-color;
            }
            &.month-range-start {}
            &.month-range-between {
              position: relative;
              background-color: $violet-color;
              border-radius: 0px;

              &:before, &:after {
                content: "";
                position: absolute;
                top: 0px;
                display: block;
                height: 100%;
                width: 20%;
                background-color: $violet-color;
              }
              &:before {
                right: 100%;
              }
              &:after {
                left: 100%;
              }
            }
          }
          @include media-breakpoint-down(lg) {
            .month-item {
              &:nth-child(3n + 3) { // last item of row
                &.month-range-between {
                  border-radius: 0px 12px 12px 0px;

                  &:after {
                    display: none;
                  }
                }
              }
              &:nth-child(3n + 1) { // first item of row
                &.month-range-between {
                  border-radius: 12px 0px 0px 12px;

                  &:before {
                    display: none;
                  }
                }
              }
            }
          }
          @include media-breakpoint-up(lg) {
            --items-per-line: 3;

            .month-item {
              &:nth-child(3n + 3) { // last item of row
                &.month-range-between {
                  border-radius: 0px 12px 12px 0px;

                  &:after {
                    display: none;
                  }
                }
              }
              &:nth-child(3n + 1) { // first item of row
                &.month-range-between {
                  border-radius: 12px 0px 0px 12px;

                  &:before {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.nights-options-wrp {
  --items-per-line: 5;

  display: grid;
  grid-template-columns: repeat(var(--items-per-line), minmax(0, 1fr));
  gap: 6px;
  padding-top: 24px;

  .night-item {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    background-color: $violet-light-color;
    border-radius: 12px;
    transition: all .3s;

    &:hover {
      background-color: $violet-medium-color;
    }
    &.nights-range-start, &.nights-range-end {
      position: relative;
      z-index: 1;
      color: #ffffff;
      background-color: $secondary-color;
    }
    &.nights-range-start {}
    &.nights-range-between {
      position: relative;
      background-color: $violet-color;
      border-radius: 0px;

      &:before, &:after {
        content: "";
        position: absolute;
        top: 0px;
        display: block;
        height: 100%;
        width: 40%;
        background-color: $violet-color;
      }
      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
    &.nights-range-end {}
    #mobileSearchDrawer & { // for mobile drawer only
    }
  }
  @include media-breakpoint-down(lg) {
    .night-item {
      &:nth-child(5n + 5) { // last item of row
        &.nights-range-between {
          border-radius: 0px 12px 12px 0px;

          &:after {
            display: none;
          }
        }
      }
      &:nth-child(5n + 1) { // first item of row
        &.nights-range-between {
          border-radius: 12px 0px 0px 12px;

          &:before {
            display: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    --items-per-line: 6;

    .night-item {
      &:nth-child(6n + 6) { // last item of row
        &.nights-range-between {
          border-radius: 0px 12px 12px 0px;

          &:after {
            display: none;
          }
        }
      }
      &:nth-child(6n + 1) { // first item of row
        &.nights-range-between {
          border-radius: 12px 0px 0px 12px;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.passengers-options-wrp {
  padding-top: 24px;

  .passengers-main-options {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 12px;

    .passengers-select-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 140px;
      column-gap: 16px;
      align-items: center;

      label {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
  .childs-age-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 16px;
    margin-top: 28px;

    .childs-age-title {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }
    .childs-main-options {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 12px;

      .child-age-select-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 140px;
        align-items: center;

        label {
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }
}

.mobile-search-btn {
  padding: 50px 10px 10px 10px;
  background-color: #CFC6FB;
  border-radius: 24px 24px 32px 32px;
  transition: all .3s;

  .act-toggle-mobile-search {
    position: relative;
    display: grid;
    grid-template-columns: 20px minmax(0, 1fr);
    align-items: center;
    column-gap: 10px;
    padding: 5px 14px;
    height: 52px;
    width: 100%;
    text-align: left;
    color: $secondary-color;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 100px;
    cursor: pointer;
    transition: all .3s;

    .label {
      position: absolute;
      bottom: calc(100% + 13px);
      left: 9px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      transition: all .3s;
    }
    i {
      font-size: 18px;
      transition: all .3s;
    }
    .values {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      column-gap: 6px;
      padding-top: 0px;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      transition: all .3s;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  #mainHeader.pinned &, &.active {
    padding: 0px;
    background-color: transparent;

    .act-toggle-mobile-search {
      grid-template-columns: 22px minmax(0, 1fr);
      column-gap: 12px;
      padding: 5px 20px;
      height: 60px;
      border-color: $gray-color;

      .label {
        bottom: 33px;
        left: 53px;
      }
      i {
        font-size: 20px;
      }
      .values {
        padding-top: 20px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
#mobileSearchDrawer {
  position: fixed;
  top: 0px;
  right: 0px;
  overflow: auto;
  overflow-x: hidden;
  z-index: 100001;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  height: 100%;
  background-color: #d9efff;
  transform: var(--drawer-transform-right-out);
  transition: var(--drawer-enter-transition-curve);

  &.open {
    transform: var(--drawer-transform-right-in);
    transition: var(--drawer-exit-transition-curve);
  }

  .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr) auto;

    .header-wrp {
      display: flex;
      justify-content: end;
      padding: 16px;

      .search-close-btn {
        width: 48px;
        height: 48px;
        font-size: 24px;
        color: $secondary-color;
        background: none;
        border: none;
      }
    }
    .content-wrp {
      overflow: hidden;
      padding: 0px 16px 16px 16px;

      .search-variables-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 8px;
        align-items: start;

        .search-variable-block {
          padding: 23px 20px;
          min-height: 64px;
          background-color: #ffffff;
          border-radius: 14px;
          transition: all .3s;

          .summary-wrp {
            display: grid;
            grid-template-columns: auto minmax(0, 1fr);
            column-gap: 16px;
            align-items: center;
            font-size: 15px;
            line-height: 18px;

            .title {
              font-weight: 600;
              color: $gray-color;
              transition: all .3s;
            }
            .value {
              font-weight: 500;
              color: $secondary-color;
              text-align: right;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              opacity: 1;
              transition: all .3s;
            }
          }

          &.destination-wrp {
            &.no-values {
              margin-bottom: 8px;
              padding: 28px 16px;
              border-radius: 24px;

              .summary-wrp {
                display: none;
              }
              .no-values-wrp {
                display: grid;
              }
            }
            .no-values-wrp {
              display: none;
              grid-template-columns: minmax(0, 1fr);
              row-gap: 16px;

              .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 27px;
              }
              .fake-field {
                display: grid;
                grid-template-columns: 24px minmax(0, 1fr);
                align-items: center;
                column-gap: 8px;
                padding: 16px;
                border: 1px solid $gray-color;
                border-radius: 12px;
                cursor: pointer;

                i {
                  font-size: 20px;
                }
                span {
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 18px;
                  color: $gray-color;
                }
              }
            }
            .destinations-drawer {
              position: fixed;
              top: 0;
              right: 0px;
              z-index: 999;
              overflow-x: hidden;
              overflow-y: auto;
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              align-content: start;
              row-gap: 32px;
              padding: 32px 16px 28px 16px;
              width: 100%;
              height: calc(100%);
              background-color: #ffffff;
              transform: translateX(100%);
              transition: all .3s;

              .actions-wrp {
                position: sticky;
                bottom: -28px;
                display: grid;
                justify-content: end;
                margin: 0px -16px -28px -16px;
                padding: 16px;
                background-color: #ffffff;
                border-top: 1px solid #ebebeb;
              }
              &.open {
                transform: translateX(0);
              }
            }
          }
          &.dates-wrp, &.nights-wrp, &.passengers-wrp {
            display: grid;
            grid-template-rows: auto minmax(0, 1fr);
            overflow: hidden;
            margin: 0px;
            transition: margin .3s;

            .summary-wrp {}
            .variable-content-wrp {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              grid-template-rows: minmax(0, 0fr);
              transition: grid-template-rows .3s;

              .variable-inner-wrp {
                display: grid;
                grid-template-rows: minmax(0, 1fr);
                grid-template-columns: minmax(0, 1fr);
                overflow: auto;
                margin: 0px -20px;
                padding: 0px 20px;

                .actions-wrp {
                  display: grid;
                  justify-content: end;
                  margin: 12px -20px 0px -20px;
                  padding: 12px 20px 0px 20px;
                  background-color: #ffffff;
                  border-top: 1px solid #ebebeb;
                }
              }
            }
            &.active {
              margin: 8px 0px;
              padding: 28px 20px 23px 20px;
              border-radius: 24px;

              .summary-wrp {
                .title {
                  font-size: 24px;
                  line-height: 27px;
                  color: $secondary-color;
                }
                .value {
                  opacity: 0;
                }
              }
              .variable-content-wrp {
                grid-template-rows: minmax(0, 1fr);
              }
              &:has(.actions-wrp) {
                padding: 28px 20px 12px 20px;
              }
            }
          }
          &.dates-wrp {
            .variable-content-wrp {
              .dates-options-wrp {
                @include dates-options-wrp-mobile();
                display: grid;
                grid-template-rows: auto minmax(0, 1fr);
                grid-template-columns: minmax(0, 1fr);

                .select-by-types-wrp {
                  .select-by-date-wrp {
                    display: grid;
                    grid-template-rows: minmax(0, 1fr) auto;
                    grid-template-columns: minmax(0, 1fr);
                    row-gap: 1px;
                    max-height: 100%;
                  }
                  .select-by-month-wrp {
                    overflow: auto;
                    height: 100%;
                  }
                }
              }
            }
          }
          &.nights-wrp {
            .variable-content-wrp {
              .variable-inner-wrp {
                .nights-options-wrp {
                  overflow: auto;
                }
              }
            }
          }
        }
      }
    }
    .footer-wrp {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px;
      background-color: #ffffff;
      border-top: 1px solid #EBEBEB;
    }
  }
}

#desktopSearchForm {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  padding: 8px;
  height: 92px;
  background-color: $violet-color;
  border-radius: 46px;
  transition: all .3s;

  > .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    background-color: #ffffff;
    border-radius: 38px;
    box-shadow: 0px 5.08px 16.25px -2.03px #18274B1A;
    box-shadow: 0px 3.05px 7.11px -3.05px #18274B1F;

    .fields-wrp {
      display: grid;
      grid-template-columns: minmax(0, 11fr) minmax(0, 6fr) minmax(0, 8fr) minmax(0, 6fr);

      .search-variable-block {
        position: relative;

        .summary-wrp {
          position: relative;
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 5px;
          align-content: center;
          padding: 10px 20px;
          height: 100%;
          color: $secondary-color;
          border-radius: 38px;
          cursor: pointer;
          transition: all .3s;

          .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }
          .value {
            overflow: hidden;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 1px;
            display: block;
            width: 1px;
            height: calc(100% - 32px); // two paddings of 16px, top - bottom
            background-color: #D9D9D9;
            opacity: 1;
            transform: translateY(-50%);
            transition: opacity .3s;
          }
          &:hover {
            background-color: $violet-light-color;

            &:after {
              opacity: 0;
            }
          }
        }
        .custom-dropdown {
          position: absolute;
          top: calc(100% + 16px);
          overflow: hidden;
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: minmax(0, 0fr);
          background-color: #ffffff;
          box-shadow: 0px 2px 8px 0px #00000026;
          border-radius: 24px;
          opacity: 0;
          transition: grid-template-rows .3s, opacity .3s;
        }

        &.destination-wrp {
          .custom-dropdown {
            left: -12px;
            width: 360px;
            max-height: 369px;

            > .inner-wrp {
              position: relative;
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              grid-template-rows: minmax(0, 1fr);

              .destination-options-search-field-wrp {
                position: absolute;
                top: 0px;
                left: 16px;
                right: 16px;
                z-index: 1;
                padding-top: 12px;
                padding-bottom: 8px;
                background-color: #ffffff;

                .fake-field {
                  height: 48px;
                }
              }
              .destination-options-wrp {
                padding: 80px 16px 20px 16px;
                overflow: auto;

                .options-group {
                  row-gap: 5px;

                  .group-title {
                    padding-left: 16px;
                  }
                  .group-items-wrp {
                    row-gap: 2px;

                    .group-item {
                      .item-main {
                        overflow: hidden;
                        border-radius: 10px;
                        transition: all .3s;

                        .item-name {
                          padding: 11px 0px 7px 48px;

                          &:before {
                            top: 11px;
                            left: 16px;
                          }
                        }

                        .childs-indicator {
                          display: flex;
                          align-items: center;
                          padding: 0px 24px;
                        }
                        &:hover {
                          background-color: $violet-light-color;
                        }
                      }
                      .item-childs-wrp {
                        .childs-inner-wrp {
                          row-gap: 2px;

                          .item-child {
                            display: flex;

                            .item-name {
                              padding: 11px 0px 7px 48px;
                              border-radius: 10px;
                              transition: all .3s;

                              &:before {
                                top: 11px;
                                left: 16px;
                              }
                              &:hover {
                                background-color: $violet-light-color;
                              }
                            }
                            &:first-child {
                              padding: 0px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.dates-wrp {
          .custom-dropdown {
            left: -161px;
            width: 656px;

            > .inner-wrp {
              .dates-options-wrp {
                @include dates-options-wrp-desktop();
              }
            }
          }
        }
        &.nights-wrp {
          .custom-dropdown {
            left: 0px;
            width: 358px;

            > .inner-wrp {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              row-gap: 20px;
              padding: 20px 20px 24px 20px;

              .nights-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
              }
              .nights-options-wrp {
                padding-top: 0px;

                .night-item {
                }
              }
            }
          }
        }
        &.passengers-wrp {
          .custom-dropdown {
            right: 0px;
            width: 328px;

            > .inner-wrp {
              padding: 20px;

              .passengers-options-wrp {
                padding: 0px;

                .passengers-main-options {
                  .passengers-select-wrp {
                    label {
                      margin-bottom: 0px;
                      font-size: 16px;
                    }
                  }
                }
                .childs-age-wrp {
                  row-gap: 18px;
                  margin-top: 24px;

                  .childs-age-title {
                    font-size: 16px;
                    line-height: 20px;
                  }
                  .childs-main-options {
                    .child-age-select-wrp {
                      label {
                        margin-bottom: 0px;
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &:first-child {
          .summary-wrp {
            padding-left: 28px;
          }
        }
        &:last-child {
          .summary-wrp {
            border-radius: 38px 0px 0px 38px;

            &:after {
              display: none;
            }
          }
        }
        &:has( + .search-variable-block .summary-wrp:hover) {
          .summary-wrp {
            &:after {
              opacity: 0;
            }
          }
        }
        &.active {
          .custom-dropdown {
            grid-template-rows: minmax(0, 1fr);
            opacity: 1;
          }
        }
      }
      &:has(.search-variable-block.active) {
        .search-variable-block:not(.active) {
          .summary-wrp {
            color: $gray-color;
          }
        }
      }
    }
    .actions-wrp {
      padding: 12px;
      border-radius: 0px 38px 38px 0px;
      transition: all .3s;

      .btn {
        padding: 10px 23px 10px 16px;
        min-height: 52px;
        border-radius: 30px;

        i {
          margin-right: 8px;
          font-size: 20px;
        }
      }
    }
    &:has(.fields-wrp .search-variable-block:last-child .summary-wrp:hover) .actions-wrp {
      background-color: $violet-light-color;
    }
  }
  &:hover, &:has(.inner-wrp .fields-wrp .search-variable-block.active) {
    box-shadow: 0px 0px 0px 4px #CEC5FB;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}