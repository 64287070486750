#checkoutHeader {
  padding: 16px 0px;

  .checkout-header-inner {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    grid-template-areas: "return logo help"
                         "steps steps steps";
    align-items: center;
    column-gap: 16px;
    row-gap: 24px;

    .return-container {
      grid-area: return;

      .return-link {
        display: inline-flex;
        padding: 12px;
        font-size: 20px;
        color: $secondary-color;
      }
    }
    .logo-container {
      grid-area: logo;
    }
    .help-container {
      grid-area: help;
      justify-self: end;

      .help-btn {
        padding: 8px 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }
    .steps-container {
      grid-area: steps;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 22px 0px;

    .checkout-header-inner {
      grid-template-areas: "logo steps help";

      .return-container {
        display: none;
      }
      .logo-container {
        img {
          width: 178px;
        }
      }
    }
  }
}
#checkoutFooter {
  padding-top: 8px;
  padding-bottom: 32px;

  .checkout-footer-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;

    .links-wrp {
      display: flex;

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $secondary-color;
        letter-spacing: 0px;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
    .copyright-wrp {
      small {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $gray-color;
        letter-spacing: 0px;
        text-align: center;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .checkout-footer-inner {
      flex-direction: row;
      justify-content: space-between;

      .links-wrp {
        a {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .copyright-wrp {
        small {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.checkout-steps-wrp {
  .steps-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  @include media-breakpoint-down(lg) {
    .steps-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 8px;

      .step-item {
        position: relative;
        padding-bottom: 12px;
        text-align: left;

        .text {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: $gray-color;

          &:before {
            content: attr(data-mob-content);
            display: inline-block;
            margin-right: 4px;
          }
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0px;
          display: block;
          height: 5px;
          width: 100%;
          background-color: $violet-light-color;
          border-radius: 4px;
        }
        &.active {
          .text {
            color: $secondary-color;
          }
          &:after {
            background-color: $violet-color;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .steps-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 32px;

      .step-item {
        .text {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: $gray-color;

          &:before {
            content: attr(data-content);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            width: 32px;
            height: 32px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: $gray-color;
            background-color: $violet-light-color;
            border-radius: 16px;
          }
        }
        &.active {
          .text {
            color: $secondary-color;

            &:before {
              color: $secondary-color;
              background-color: $violet-color;
            }
          }
        }
      }
    }
  }
}

.checkout-grid {
  display: grid;
  grid-template-areas: "content"
                       "summary"
                       "actions";
  grid-template-columns: minmax(0, 1fr);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  padding: 0px 16px;
  max-width: 537px;

  .return-container {
    grid-area: return;
    justify-content: start;
    margin-bottom: 32px;

    a {
      color: $secondary-color;
      text-decoration: none;

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }
  .content-container {
    grid-area: content;
  }
  .summary-container {
    grid-area: summary;
  }
  .actions-container {
    grid-area: actions;
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: minmax(0, 5fr) minmax(0, 4fr);
    column-gap: 125px;
    align-items: start;
    grid-template-areas: "return return"
                         "content summary"
                         "actions summary";
    padding-top: 28px;
    max-width: 1062px;
  }
}

.checkout-title {
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: $secondary-color;
  letter-spacing: 0.6px;

  &:has(+ .checkout-caption) {
    margin-bottom: 4px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 28px;
    line-height: 36px;
  }
}
.checkout-caption {
  display: block;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: $gray-color;
  letter-spacing: 0px;

  @include media-breakpoint-up(lg) {}
}

.summary-dropdown {
  overflow: hidden;
  background-color: #B5E0FF;
  border-radius: 24px;

  form {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    
    .input-group {
      input {
        border-color: #191B46;
        border-radius: 12px;
        background: transparent;
        border-right: 0;
        color: #191B46;
      }
      
      button {
        background-color: transparent;
        border-left: 0;
        
        &:hover {
          background-color: transparent!important;
          text-decoration: underline;
        }
      }
    }
  }
  
  .voucher-codes {
    list-style: none;
    padding-left: 0!important;
    
    li {
      .name {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 18px;
      }
      
      a {
        float: right;
        font-size: 15px;
        font-weight: 600;
        
        i {
          font-size: 12px;
          font-weight: 600;
          padding-left: 5px;
        }
      }
    }
    
  }
  
  .summary-dropdown-title {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    align-items: center;
    column-gap: 16px;
    padding: 16px 20px;
    min-height: 58px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;

    .title {
      display: flex;
      justify-content: start;
      align-items: center;
      column-gap: 12px;

      i {
        display: inline-block;
        width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        background-color: #FFF06B;
        border-radius: 12px;
      }
    }
    > i {
      font-size: 18px;
      transition: transform .3s;
    }
  }
  .summary-dropdown-content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 0fr);
    transition: grid-template-rows .3s;
    padding: 0px 20px 0px 20px;
    overflow: hidden;
  }
  &.open {
    .summary-dropdown-title {
      > i {
        transform: rotate(180deg);
      }
    }
    .summary-dropdown-content {
      grid-template-rows: minmax(0, 1fr);
    }
  }

  @include media-breakpoint-up(lg) {
    .summary-dropdown-title {
      min-height: 80px;

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

.summary-offer-details {
  .offer-summary-wrp {
    padding-bottom: 20px;

    .summary-top {
      display: grid;
      grid-template-columns: 79px minmax(0, 1fr);
      column-gap: 12px;
      align-items: center;

      .photo-wrp {
        img {
          border-radius: 14px;
        }
      }
      .info-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);

        .name {
          margin-bottom: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
        .location {
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
    .summary-bottom {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 8px;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgba(25, 27, 70, 0.2);

      .offer-feature {
        display: flex;
        justify-content: space-between;
        column-gap: 16px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0px;

        .value {
          text-align: right;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .offer-summary-wrp {
      padding-bottom: 28px;

      .summary-top {
        grid-template-columns: 99px minmax(0, 1fr);
        column-gap: 16px;

        .info-wrp {
          font-size: 15px;
          line-height: 20px;
        }
      }
      .summary-bottom {
        row-gap: 14px;
        margin-top: 24px;
        padding-top: 24px;

        .offer-feature {
          font-size: 16px;
        }
      }
    }
  }
}
.summary-flight-details {
  .flight-details {
    padding: 12px 0px 20px 0px;

    .flights-info-wrp {
      @include flights-table();
      row-gap: 20px;

      .block-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
      &.no-border {
        padding: 0px;
        margin: 0px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .flight-details {
      padding: 4px 0px 32px 0px;

      .flights-info-wrp {
        margin-bottom: 24px;
        padding-bottom: 24px;

        .block-title {
          font-size: 15px;
          line-height: 20px;
        }
        .flights-list-wrp {
          .single-flight-wrp {
            .flight-line {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.summary-lines-wrp {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 14px;
  margin-bottom: 40px;

  .summary-line {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0px;

    .title {}
    .value {
      text-align: right;
    }
    &.total-line {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    .summary-line {
      font-size: 18px;
      line-height: 28px;

      &.total-line {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

.passengers-form-wrp {
  padding-top: 24px;

  .passengers-inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 40px;

    .radio-group {
      label {
        min-width: 96px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 0px;
  }
}
.conditaions-wrp {
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    .checkout-title {
      margin-bottom: 24px;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.3px;
    }
  }
}

.baggage-group-wrp {
  .checkout-title {}
  .baggage-list {
    .baggage-item {
      display: grid;
      grid-template-columns: 88px minmax(0, 1fr);
      column-gap: 19px;
      padding: 20px 16px;
      background-color: $violet-light-color;
      border-radius: 24px;

      .photo-wrp {
        margin-top: -4px;
        margin-bottom: -4px;
      }
      .texts-wrp {
        container-name: baggage-texts-container;
        container-type: inline-size;

        display: grid;
        grid-template-columns: minmax(0, 1fr);
        align-items: start;
        row-gap: 10px;
        letter-spacing: 0px;

        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }
        .desc {
          margin: 0px;
          font-size: 15px;
          line-height: 24px;
          color: $gray-color;
        }
        .actions-wrp {
          .btn {
            min-height: 48px;
          }

          &.status-included {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            column-gap: 9px;
            padding-top: 18px;

            i {
              font-size: 24px;
            }
            span {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
            }
          }
          &.status-add {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 16px;
            row-gap: 12px;
            padding-top: 6px;

            .btn {
              min-height: 48px;
            }
            .price {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
            }
          }
          &.status-edit {
            display: grid;
            grid-template-columns: auto 48px;
            justify-content: start;
            gap: 12px;
            padding-top: 6px;

            .edit-btn {
              padding: 10px 16px 10px 12px;

              i {
                font-size: 18px;
              }
            }
            .remove-btn {
              i {
                font-size: 18px;
              }
            }

            @container baggage-texts-container (width < 189px) {
              //.status-edit {
                grid-template-columns: minmax(0, 1fr);
              //}
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .baggage-list {
      .baggage-item {
        .photo-wrp {}
        .texts-wrp {
          .title {
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
          }
          .desc {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.baggage-modal {
  .modal-header {}
  .modal-body {
    .baggage-description-wrp {
      display: grid;
      grid-template-columns: 65px minmax(0, 1fr);
      column-gap: 16px;
      align-items: start;
      padding: 20px 16px;
      background-color: $violet-light-color;
      border-radius: 24px;

      .photo-wrp {
        margin-top: -14px;
        margin-bottom: -14px;
      }
      .texts-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0px;

        .price {
          font-size: 15px;
        }
      }
    }
    .flight-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 12px;

      .flight-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0px;
      }
      .passengers-list {
        .passenger-item {
          label {
            display: grid;
            grid-template-columns: minmax(0, 1fr) auto;
            align-items: center;
            column-gap: 16px;
            padding: 22px 16px;
            background-color: $violet-light-color;
            border-radius: 12px;
            cursor: pointer;

            input {
              display: none;
            }
            span {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0px;
            }
            &:after {
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              font-size: 14px;
              line-height: 18px;
              text-align: center;
              color: $secondary-color;
              font-family: FontAwesome;
              background-color: #ffffff;
              border: 1px solid #D6D6D6;
              border-radius: 5px;
              transition: all .3s;
            }
            &:has(input:checked) {
              &:after {
                content: '\f00c';
                background-color: $primary-color;
                border-color: $secondary-color;
              }
            }
          }
        }
      }
    }
  }
  .modal-footer {
    display: block;
    padding: 16px;

    .total-line {
      display: flex;
      justify-content: space-between;
      align-items: start;
      column-gap: 16px;
      margin: 0px 0px 16px 0px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px;

      .value {
        text-align: right;
      }
    }
    .actions-wrp {
      margin: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-body {
      padding: 24px 32px 0px 32px;

      .baggage-description-wrp {
        .texts-wrp {
          row-gap: 14px;
          font-size: 15px;
          line-height: 20px;

          .price {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
      .flight-wrp {
        .flight-title {
          font-size: 15px;
          line-height: 20px;
        }
        .passengers-list {
          .passenger-item {
            label {
              padding: 18px;
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 16px 32px;
    }
  }
}

.payment-sum-wrp {
  .chekout-title {}
  .payment-sum-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 6px;

    label {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 2px;
      padding: 24px 5px 16px 5px;
      text-align: center;
      letter-spacing: 0px;
      background-color: $violet-light-color;
      border-radius: 14px;
      cursor: pointer;
      transition: all .3s;

      input { display: none; }
      .icon-wrp {
        display: block;
        margin-bottom: 14px;
        height: 30px;

        i {
          font-size: 30px;
        }
      }
      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
      .sum {
        font-size: 12px;
        line-height: 18px;
        color: $gray-color;
        transition: all .3s;
      }
      &:has(input:checked) {
        background-color: $violet-color;

        .sum {
          color: #333446;
        }
      }
      &:hover {
        background-color: $violet-medium-color;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .payment-sum-list {
      gap: 8px;

      label {
        row-gap: 4px;

        .icon-wrp {
          margin-bottom: 12px;
        }
        .title {
          font-size: 15px;
          line-height: 20px;
        }
        .sum {
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
  }
}
.payment-methods-wrp {
  .payment-methods-list {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 8px;

    .payment-method-item {
      label {
        display: grid;
        grid-template-columns: 20px minmax(0, 1fr) auto;
        column-gap: 12px;
        align-items: center;
        padding: 20px 16px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0px;
        background-color: $violet-light-color;
        border-radius: 14px;
        cursor: pointer;
        transition: all .3s;

        input { display: none; }
        &:before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-color: #ffffff;
          border: 1px solid #D6D6D6;
          border-radius: 10px;
          transition: all .3s;
        }

        &:hover {
          background-color: $violet-medium-color;
        }
        &:has(input:checked) {
          background-color: $violet-color;

          &:before {
            background-color: $secondary-color;
            border-color: $secondary-color;
            box-shadow: inset 0 0 0 3px #ffffff;
          }
        }
      }
    }
    &:not(.open) {
      .payment-method-item {
        &:nth-child(n + 7) {
          display: none;
        }
      }
    }
  }
}


.thank-you-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 129px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 16px;
  max-width: 832px;

  .top-container {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    justify-items: center;
    padding-top: 38px;

    .icon-wrp {
      margin-bottom: 24px;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        font-size: 26px;
        border-radius: 32px;
        background-color: #E3FF8C;
      }
    }
    .text-wrp {
      margin-bottom: 24px;
      p {
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: center;

      }
    }
    .actions-wrp {
      .btn {
        min-width: 230px;
      }
    }
  }
  .bottom-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 100%;

    .offer-summary-wrp {
      .summary-top {
        display: grid;
        grid-template-columns: 79px minmax(0, 1fr);
        column-gap: 12px;
        align-items: center;

        .photo-wrp {
          img {
            border-radius: 14px;
          }
        }
        .info-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);

          .name {
            margin-bottom: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
          }
          .location {
            font-size: 13px;
            line-height: 19px;
          }
        }
      }
      .summary-bottom {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 8px;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(25, 27, 70, 0.2);

        .offer-feature {
          display: flex;
          justify-content: space-between;
          column-gap: 16px;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0px;

          .value {
            text-align: right;
          }
        }
      }
    }
    .flight-details {
      letter-spacing: 0px;

      .flights-title {
        display: block;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
      }
      .flights-info-wrp {
        @include flights-table();

        .block-title {
          margin-bottom: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
        &.no-border {
          padding: 0px;
          margin: 0px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    row-gap: 69px;

    .top-container {
      padding-top: 70px;

      .icon-wrp {
        i {
          font-size: 24px;
        }
      }
      .text-wrp {
        p {
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0.4px;
        }
      }
    }
    .bottom-container {
      .offer-summary-wrp {
        .summary-top {
          grid-template-columns: 99px minmax(0, 1fr);
          column-gap: 16px;

          .info-wrp {
            font-size: 15px;
            line-height: 20px;
          }
        }
        .summary-bottom {
          row-gap: 14px;
          margin-top: 24px;
          padding-top: 24px;

          .offer-feature {
            font-size: 16px;
          }
        }
      }
      .flight-details {
        .flights-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
        .flights-info-wrp {
          margin-bottom: 24px;
          padding-bottom: 24px;

          .block-title {
            font-size: 15px;
            line-height: 20px;
          }
          .flights-list-wrp {
            .single-flight-wrp {
              .flight-line {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}