.content-grid, .content-full-width {
  --padding-inline: 16px;
  --content-wide-max-width: 1240px;
  --breakout-xl-max-width: 1440px;
  --content-small-max-width: 820px;

  --content-wide-size: calc((var(--content-wide-max-width) - var(--content-small-max-width)) / 2);


  display: grid;
  grid-template-columns:
    [full-width-start] minmax(var(--padding-inline), 1fr)
    [content-wide-start] minmax(0, var(--content-wide-size))

    [content-small-start]
      min(100% - (var(--padding-inline) * 2), var(--content-small-max-width))
    [content-small-end]

    minmax(0, var(--content-wide-size)) [content-wide-end]
    minmax(var(--padding-inline), 1fr) [full-width-end];

  > *:not(.content-full-width, .content-breakout-xl) {
    grid-column: content-wide;
  }
  > .content-full-width {
    grid-column: full-width;
    display: grid;
    grid-template-columns: inherit;
  }
  > .content-small {
    grid-column: content-small;
  }
  > .content-wide {
    grid-column: content-wide;
  }

  @include media-breakpoint-down(lg) {
    .content-sm-full-width {
      grid-column: full-width;
    }
  }

  @include media-breakpoint-up(lg) {
    --padding-inline: 40px;
  }

  @include media-breakpoint-up(xl) {
    --padding-inline: 100px;
  }
}

#pages_detailed {
  .content-grid {
    > * {
      grid-column: content-small;
    }
    > .content-full-width {
      grid-column: full-width;
      display: grid;
      grid-template-columns: inherit;
    }
    > .content-small {
      grid-column: content-small;
    }
    > .content-wide {
      grid-column: content-wide;
    }
  }
}