@use "sass:map";

header {
  //@import "header/mega-menu";
}

#mainHeader {
  position: fixed;
  //position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 11;

  .top-line-wrp {
    display: none;
    padding: 5px 0px;
    height: 28px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0px;
    background-color: $violet-color !important;

    .inner-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);

      .left-block, .right-block {
        display: none;
      }
    }
  }
  > .content-grid {
    background-color: #ffffff;
    transition: transform .3s;

    .header-top-part {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      column-gap: 16px;
      padding: 18px 0px;

      .main-nav-container {
        display: none;
      }
      .user-actions-container {
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
        align-items: center;

        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 8px;
          color: $secondary-color;
          text-decoration: none;

          i {
            font-size: 16px;
            flex-wrap: wrap;
            color: $secondary-color;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            align-content: center;
            background: $primary-color;
            border-radius: 50%;
          }
        }
      }
      .main-menu-burger-container {}
    }
    .header-bottom-part {
      padding: 3px 0px 12px 0px;

      .fake-search {
        padding: 50px 10px 10px 10px;
        background-color: #CFC6FB;
        border-radius: 24px 24px 32px 32px;
        transition: all .3s;

        .fake-field {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 10px;
          padding: 8px 14px;
          height: 52px;
          border-radius: 100px;
          background-color: #ffffff;
          border: 1px solid #ffffff;
          transition: all .3s;

          .label {
            position: absolute;
            left: 10px;
            bottom: calc(100% + 12px);
            transition: all .3s;
          }
          .text {
            padding-top: 0px;
          }
        }
      }
    }
  }
  &.pinned {
    > .content-grid {
      //position: fixed;
      //top: initial;
      //left: 0px;
      //right: 0px;
      //bottom: 100%;
      grid-template-rows: auto minmax(0, 0fr);
      align-content: start;
      //transform: translateY(calc(100% - 2px));
      transition: grid-template-rows .15s;

      .header-bottom-part {
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s;

        .fake-search {
          padding: 0px;
          border-radius: 30px;
          background-color: #ffffff;

          .fake-field {
            padding: 8px 20px;
            height: 60px;
            border-color: $gray-color;

            .label {
              bottom: 34px;
              left: 54px;
            }
            .text {
              padding-top: 20px;
            }
          }
        }
      }
    }
    &.sticked, &.mega-active {
      > .content-grid {
        //transform: translateY(calc(100% - 2px));
      }
    }
    &.sticked {
      > .content-grid {
        grid-template-rows: auto minmax(0, 1fr);
      }
      .header-bottom-part {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .top-line-wrp {
      display: grid;

      .inner-wrp {
        grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
        column-gap: 16px;

        .left-block, .right-block {
          display: block;
        }
      }
    }
    > .content-grid {
      .header-top-part {
        grid-template-columns: 1fr auto 1fr;
        padding: 28px 0px;

        .logo-container {
          a {
            img {
              width: 176px;
            }
          }
        }
        .main-nav-container {
          display: block;
        }
        .main-menu-burger-container {
          display: none;
        }
      }
    }
  }
}


.burger-btn {
  padding: 6px 12px;
  background: transparent;
  border: none;

  .hamburger-lines {
    position: relative;
    height: 24px;
    width: 24px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    .line {
      position: absolute;
      top: 50%;
      left: 3px;
      width: 18px;
      height: 2px;
      background-color: black;
      transition: all 400ms cubic-bezier(.84,.06,.52,1.8);

      &.line1 {
        transform: translateY(-7px);
        animation-delay: 100ms;
      }
      &.line2 {
        //transition: transform 0.2s ease-in-out;
      }
      &.line3 {
        transform: translateY(7px);
        animation-delay: 250ms;
      }
    }

    &.open {
      .line {
        &.line1 {
          transform: rotate(45deg);
        }
        &.line2 {
          opacity: 0;
        }
        &.line3 {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
