//
// Shop module styles
// --------------------------------------------------
#products_listing {
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
}
.products-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 48px;
  column-gap: 16px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  //456px
}

// Product card
.product-placeholder {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-content: start;
  row-gap: 16px;

  .photo {
    @include shimmer-bg(#F1F1F1);
    aspect-ratio: 298 / 265;
    width: 100%;
    border-radius: 14px;
  }
  .texts-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    justify-content: start;
    row-gap: 10px;

    span {
      @include shimmer-bg(#F1F1F1);
      border-radius: 6px;

      &.location {
        margin-bottom: -2px;
        width: 42%;
        height: 18px;
      }
      &.title {
        width: 100%;
        height: 48px;
      }
      &.dates {
        width: 58%;
        height: 19px;
      }
      &.price {
        width: 15%;
        height: 18px;
      }
    }
  }
}
.product-card {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  align-content: start;
  row-gap: 16px;
  height: 100%;

  .tags {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
  }
  .tags-bottom {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
    pointer-events: none;


    .badge {
      &.no-bg {
        padding: 0px;

        img {
          max-width: 128px;
        }
      }
    }
  }
  .photo-wrp {
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 14px;

    .swiper {
      .swiper-pagination {
        bottom: 12px;

        .swiper-pagination-bullet {
          margin: 0px 2px;
          width: 6px;
          height: 6px;
          background-color: #FFFFFF;
          opacity: 0.6;

          &.swiper-pagination-bullet-active {
            width: 6px;
            background-color: #FFFFFF;
            opacity: 1;
          }
        }
      }
      .swiper-nav-btn {
        display: none;
      }
    }
  }
  .texts-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto 1fr auto;
    color: $secondary-color;

    .top-block {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 10px;

      .location-title {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .hotel-title {
        font-size: 15px;
        line-height: 24px;
      }
    }
    .spacer {
      min-height: 10px;
    }
    .bottom-block {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 12px;

      .details-wrp {
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 10px;
          row-gap: 10px;
          margin-bottom: 0px;
          font-size: 13px;
          line-height: 19px;
          color: $gray-color;
          border: none;

          li {
            margin-bottom: 0px;
          }
        }
      }
      .price-wrp {
        .product-price {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 8px;

          .price, .old-price {
            font-size: 15px;
            line-height: 18px;
          }
          .out-of-stock {
            font-size: 15px;
            line-height: 18px;
            color: $gray-color;
          }
          .price {
            font-weight: 600;
            color: $secondary-color;
          }
          .old-price {
            text-decoration: line-through;
            color: $gray-color;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .photo-wrp {
      .swiper {
        .swiper-pagination {
          display: none;
        }
      }
      &:hover {
        .swiper {
          .swiper-pagination {
            display: block;
          }
          .swiper-nav-btn {
            display: flex;
          }
        }
      }
    }
  }
}


// List variant

.product-list .product-list-thumb {
  @include border-radius($card-border-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
@include media-breakpoint-up(sm) {
  .product-list {
    .product-list-thumb {
      width: $product-list-thumb-width;
      @include border-radius($card-border-radius);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
    }
    .card-body-hidden {
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      box-shadow: none !important;
    }
  }
}
@include media-breakpoint-up(lg) {
  .product-list:hover + .border-top { opacity: 0; }
}




// Product title

.product-title > a {
  transition: $product-title-link-transition;
  color: $product-title-link-color;
  text-decoration: none !important;
  &:hover {
    color: $product-title-link-hover-color;
  }
}


// Product meta

.product-meta {
  transition: $product-meta-transition;
  color: $product-meta-color;
  text-decoration: none !important;
  &:hover {
    color: $product-meta-hover-color;
  }
}


// Floating button

.product-floating-btn {
  position: absolute;
  right: .75rem;
  bottom: .75rem;
  transition: opacity .2s ease-in-out;
  opacity: 0;
  z-index: 10;

  .product-card:hover & {
    opacity: 1;
  }
}

.filters-sort-wrp {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  margin-bottom: 24px;

  .sort-block {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    column-gap: 20px;
    align-items: center;

    .sort-wrp {
      order: 2;
    }
    .total-offers {
      order: 1;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: $gray-color;

      .loader {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: start;
        column-gap: 11px;


        .loader-placeholder {
          @include shimmer-bg(#F1F1F1);
          height: 24px;
          width: 100%;
          max-width: 89px;
          border-radius: 6px;
        }
        .loader-small-circle {
          flex-shrink: 0;
        }
      }
      .result {
        display: flex;
        justify-content: start;
        align-items: center;
        min-height: 26px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    column-gap: 40px;

    .sort-block {
      grid-template-columns: minmax(0, 1fr);
      align-content: start;
      row-gap: 16px;

      .sort-wrp {
        order: 1;
      }
      .total-offers {
        order: 2;
        text-align: right;

        .loader {
          justify-content: end;

          .loader-placeholder {
            order: 2;
          }
          .loader-small-circle {
            order: 1;
          }
        }
        .result {
          justify-content: end;
        }
      }
    }
  }
}

// filter
.filter-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
.mobile-filter-toggle-btn {
  z-index: 2;
  position: fixed;
  bottom: 18px;
  left: 50%;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  transform: translateX(-50%);

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    width: 26px;
    height: 26px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: $secondary-color;
    background-color: #ffffff;
    border-radius: 8px;
  }
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}
#filter {
  .filters-listing {
    display: flex;
    flex-direction: row;

    .filters-loader {
      display: flex;
      flex-direction: row;
      gap: 6px;

      span {
        @include shimmer-bg(#F1EEFE);

        height: 48px;
        border-radius: 12px;

        &:nth-child(1) { width: 74px; }
        &:nth-child(2) { width: 185px; }
        &:nth-child(3) { width: 110px; }
        &:nth-child(4) { width: 114px; }
      }
    }
    .filter-group-wrp {
      .filter-group-title {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
      }
      .filter-group-items {

      }
    }
  }
  .filters-summary {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 40px;
    left: 0px;
    overflow: hidden;
    z-index: 9999;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr) auto;
    height: calc(100% - 40px);
    width: 100%;
    background-color: #ffffff;
    border-radius: 24px 24px 0px 0px;
    transform: translateY(100%);
    transition: all .3s;

    .filter-header {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      padding: 18px 40px;
      border-bottom: 1px solid #EBEBEB;

      .filter-title {
        font-weight: 600;
        line-height: 15px;
        line-height: 18px;
        text-align: center;
      }
      .btn-close {
        position: absolute;
        top: 21px;
        right: 20px;
        opacity: 1;
        padding: 2px;
        background-size: 16px;
      }
    }
    .filters-listing {
      overflow: auto;
      flex-direction: column;
      padding: 32px 16px;

      .filter-group-wrp {
        .filter-group-title {
          margin-bottom: 21px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }
        .filter-group-items {
          .inner-wrp {
            list-style: none;
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 16px;
            margin: 0px;
            padding-left: 0px;

            li {
              .filter-check {

              }
              &.widget-filter-item {
                &.child {
                  padding-left: 26px;
                }
              }
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 32px;
          padding-bottom: 32px;
          border-bottom: 1px solid #EBEBEB;
        }
      }
    }
    .filter-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;
      padding: 16px;
      border-top: 1px solid #EBEBEB;

      .pf-clear {}
      .act-submit-filter {
        i {
          font-size: 17px;
        }
      }
    }

    &.is_shown {
      transform: translateY(0);
    }
  }
  @include media-breakpoint-up(lg) {
    .filter-header {
      display: none;
    }
    .filters-listing {
      column-gap: 6px;

      .filter-group-wrp {
        position: relative;

        .filter-group-title {
          margin-bottom: 0px;
          padding: 12px 16px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          white-space: nowrap;
          background-color: $violet-light-color;
          border-radius: 12px;
          cursor: pointer;
          transition: all .3s;

          &:hover {
            background-color: $violet-medium-color;
          }
          &:focus, &.active {
            background-color: $violet-color;
          }
        }
        .filter-group-items {
          position: absolute;
          z-index: 3;
          overflow: hidden;
          display: none;
          margin: 8px 0px;
          width: max-content;
          background-color: #ffffff;
          box-shadow: 0px 2px 8px 0px #00000026;
          border-radius: 24px;

          .inner-wrp {
            overflow: auto;
            list-style: none;
            margin: 0px;
            padding: 12px 8px;
            max-width: 300px;
            max-height: 360px;

            li {
              padding: 11px 16px;
              border-radius: 15px;
              cursor: pointer;
              transition: all .3s;

              .form-check {
                //display: flex;
                //justify-content: space-between;
                //column-gap: 12px;
                //padding-left: 0px;
                //
                //.form-check-label {
                //  display: flex;
                //  justify-content: space-between;
                //  column-gap: 16px;
                //
                //  input {
                //    display: none;
                //  }
                //  &:before {
                //    content: "";
                //    display: flex;
                //    align-items: center;
                //    justify-content: center;
                //    margin-top: 2px;
                //    width: 20px;
                //    height: 20px;
                //    font-family: FontAwesome;
                //    border-radius: 5px;
                //    background-color: #ffffff;
                //    border: 1px solid $gray-color;
                //    transition: all .3s;
                //  }
                //  &:has(input:checked) {
                //    &:before {
                //      content: "\f00c";
                //      background-color: $primary-color;
                //      border-color: $secondary-color;
                //    }
                //  }
                //}
                //.childs-indicator {
                //  margin-right: -10px;
                //  padding: 0px 10px;
                //}
              }
              &:hover {
                background-color: $violet-light-color;
              }

              &.widget-filter-item.child {
                margin-left: 36px;
              }
            }
          }
        }
        &.open {
          .filter-group-title {
            background-color: $violet-color;
          }
          .filter-group-items {
            display: block;
          }
        }
      }
    }
    .filters-summary {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: start;
      align-items: start;
      gap: 6px;
      margin-top: 50px;

      .btn-filter {
        i {
          margin-left: 8px;
          margin-bottom: -2px;
          font-size: 18px;
        }
      }
    }
    .filter-footer {
      display: none;
    }
  }
}

// Star rating

.star-rating {
  display: inline-block;
  white-space: nowrap;
  line-height: 1;
  vertical-align: middle;
  .star-rating-icon {
    display: inline-block;
    margin-right: .1875rem;
    color: $star-color;
    font-size: $star-size;
    vertical-align: middle;
    &.active {
      color: $star-active-color;
    }
  }
}

// Shop specific page title that overlaps the content below

.page-title-overlap {
  padding-bottom: 6.375rem;
  & + * {
    margin-top: -4.875rem;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 5.5rem;
  }
}


// Interactive credit card

.credit-card-wrapper {
  margin: 1.875rem -.9375rem;
  @include media-breakpoint-down(sm) {
    .jp-card-container { width: 16.25rem !important; }
    .jp-card { min-width: 15.625rem !important; }
  }
}


// Product details (Single product)

@include media-breakpoint-up(lg) {
  .product-details {
    width: 100%;
    max-width: 26rem;
    padding-top: 0.25rem;
  }
}


// Poduct availability badge (Single product)

.product-badge {
  position: absolute;
  top: 50%;
  right: -1.75rem;
  padding: {
    top: .425rem;
    left: .625rem;
    right: 1rem;
    bottom: .425rem;
  }
  transform: translateY(-50%);
  @include border-radius($border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: $font-size-ms;

  &::after {
    position: absolute;
    bottom: -.475rem;
    right: .25rem;
    height: 0;
    width: 0;
    transform: rotate(-45deg);
    border: solid transparent;
    content: " ";
    pointer-events: none;
    border-width: .525rem;
  }

  > i {
    margin: {
      top: -.125rem;
      right: .25rem;
    }
    font-size: 1.2em;
    vertical-align: middle;
  }

  &.product-available {
    background-color: $success;
    color: $white;
    &::after {
      border-color: rgba($success, 0);
      border-bottom-color: darken($success, 10%);
    }
  }
  &.product-not-available {
    background-color: $gray-200;
    color: $gray-700;
    &::after {
      border-color: rgba($gray-200, 0);
      border-bottom-color: darken($gray-200, 8%);
    }
  }
  @include media-breakpoint-down(lg) {
    right: -.75rem;
  }
}
.modal .product-badge { right: -.575rem; }


// Product gallery
.cloudimage-360-icons-container {
  display: none !important;
}
.product-gallery-block {
  display: flex;

  #photo_3d {
    #photo_3d_btn {
      position: absolute;
      top: 0px;
      left: initial;
      right: 0px;
      z-index: 102;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
    &.active {
      position: relative;
    }
  }
}
.photo_3d_big {
  padding: 0px !important;
  max-width: 95vh !important;
  max-height: 95vh !important;

  canvas {
    max-height: 90vh !important;
    width: auto !important;
  }
}
.product-gallery-thumblist {
  margin-left: -($product-gallery-thumb-spacing * .8);
}
.product-gallery-thumblist-item {
  display: block;
  position: relative;
  width: $product-gallery-thumb-size;
  height: $product-gallery-thumb-size;
  margin: $product-gallery-thumb-spacing;
  transition: $product-gallery-thumb-transition;
  border: $product-gallery-thumb-border-width solid $product-gallery-thumb-border-color;
  @include border-radius($product-gallery-thumb-border-radius);
  text-decoration: none !important;
  overflow: hidden;
  > img {
    display: block;
    width: 100%;
    transition: opacity .2s ease-in-out;
    opacity: .6;
  }
  .product-gallery-thumblist-item-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding: .25rem;
    transform: translateY(-50%);
    color: $product-gallery-thumb-color;
    font-size: $product-gallery-thumb-font-size;
    text-align: center;
    > i {
      display: block;
      margin-bottom: .25rem;
      font-size: 1.5em;
    }
  }
  &:hover {
    border-color: $product-gallery-thumb-hover-border-color;
    > img { opacity: 1; }
  }
  &.active {
    border-color: $product-gallery-thumb-active-border-color;
    > img { opacity: 1; }
  }
}
.product-gallery-preview {
  position: relative;
  width: 100%;
  margin-top: $product-gallery-thumb-spacing;
    
  @include media-breakpoint-up(sm) {
    margin-left: $product-gallery-thumb-spacing;
  }
}
.product-gallery-preview-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: $product-gallery-preview-transition;
  opacity: 0;
  z-index: 1;
  > img {
    display: block;
    width: 100%;
  }
  &.active {
    position: relative;
    opacity: 1;
    z-index: 10;
  }
  &:hover { cursor: zoom-in; }
}
@include media-breakpoint-down(sm) {
  .product-gallery-block { display: block; }
  .product-gallery-thumblist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: {
      right: -$spacer;
      left: -$spacer;
    }
    padding-top: $spacer;
  }
  .product-gallery-thumblist-item {
    margin: $product-gallery-thumb-spacing * .5;
  }
}
