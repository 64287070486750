#mainMenuMob {
  position: fixed;
  top: 0px;
  right: 0px;
  overflow: auto;
  overflow-x: hidden;
  z-index: 100001;
  display: grid;
  max-width: 328px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  transform: var(--drawer-transform-right-out);
  transition: var(--drawer-enter-transition-curve);

  &.open {
    transform: var(--drawer-transform-right-in);
    transition: var(--drawer-exit-transition-curve);
  }
  .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr);
    align-content: start;
    overflow: hidden;

    .header-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto;
      align-items: center;
      column-gap: 16px;
      padding: 28px 25px 18px 32px;

      .action-wrp {
        .btn-return {
          display: flex;
          align-items: center;
          column-gap: 6px;
          padding: 6px 0px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: $secondary-color;
          background-color: transparent;
          border: none;

          i {
            font-size: 16px;
          }
        }
      }
      .menu-close-btn {
        padding: 0px 7px;
        font-size: 24px;
        color: $secondary-color;
        background: transparent;
        border: none;
      }
    }
    .content-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      //grid-template-rows: minmax(0, 1fr) auto;
      align-items: start;
      row-gap: 40px;
      //height: 100%;
      padding: 10px 25px 50px 32px;
      overflow: auto;

      .menu-groups-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 24px;

        .menu-group-wrp {
          .menu-item {
            display: flex;
            column-gap: 10px;
            padding: 0px 0px;
            width: 100%;
            text-align: left;
            color: $secondary-color;

            &.button {
              position: relative;
              padding-right: 20px;
              background-color: transparent;
              border: none;

              &:after {
                content: "\f054";
                position: absolute;
                top: 50%;
                right: 6px;
                font-size: 16px;
                font-family: FontAwesome;
                transform: translateY(-50%);
              }
            }
            &[data-lvl="1"] {
              font-weight: 600;
              font-size: 22px;
              line-height: 28px;
              letter-spacing: 0.3px;
            }
            &[data-lvl="2"] {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0.3px;
            }
            &[data-lvl="3"] {
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              letter-spacing: 0.3px;
            }
          }
          .inner-menu-group-wrp {
            --menu-header-height: 82px;

            position: fixed;
            top: var(--menu-header-height);
            right: 0px;
            z-index: 999;
            overflow-x: hidden;
            overflow-y: auto;
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            align-content: start;
            row-gap: 32px;
            padding: 10px 25px 28px 32px;
            width: 100%;
            height: calc(100% - var(--menu-header-height));
            background-color: #ffffff;
            transform: translateX(100%);
            transition: all .3s;

            .inner-menu-group-header {
              .inner-menu-group-title {
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
                letter-spacing: 0.3px;
              }
            }
            .custom-mob-block {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              justify-content: start;
              justify-items: start;
              row-gap: 16px;
              padding: 20px;
              width: 100%;
              border-radius: 16px;
              background-color: $violet-color;

              > span {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
              }
              .btn {
                min-width: auto;
              }
            }
            .inner-menu-group-items-wrp {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              row-gap: 22px;

              .inner-menu-group-item {

              }
            }
            &.open {
              transform: translateX(0);
            }
            &:not([data-parent="0"]) {
              //top: 0;
              //height: 100%;
            }
          }
        }
      }
      .bottom-content-wrp {
        align-self: end;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 40px;

        .fake-help {
          height: 140px;
          width: 100%;
          border-radius: 16px;
          background-color: $violet-color;
        }
        .additional-menu-items-wrp {
          list-style: none;
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 8px;
          margin: 0px;
          padding: 0px;

          li {
            display: flex;
            justify-content: start;

            a {
              display: grid;
              grid-template-columns: 20px minmax(0, 1fr);
              column-gap: 12px;
              padding: 6px 0px;
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              color: $secondary-color;

              i {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
#mainMenuMega {
  > ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 7px;
    margin: 0px;
    margin-bottom: -18px;
    padding: 0;

    > li {
      padding: 16px 16px 38px 16px;
    }
  }
  .menu-section {
    .menu-item {
      &[data-lvl="1"] {
        position: relative;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: $secondary-color;

        &:after {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 0px;
          height: 2px;
          background-color: #000;
          transition: all .3s;
        }
        &.active, &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
  .dropdown-content-wrp {
    position: absolute;
    top: 113px;
    left: 0px;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    max-height: 0px;
    background-color: #ffffff;

    .container {
      padding-left: 24px;
      padding-right: 24px;
    }
    .dropdown-content-inner-wrp {
      --column-gap: 20px;
      --columns-count: 6;

      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 20px;
      padding: 30px 0px 62px 0px;

      .mega-column-wrp {
        --column-size: 1;
        --columns-inner-count: 1;
        --column-single-width: calc((100% - (var(--column-gap) * (var(--columns-count) - 1))) / var(--columns-count));

        display: grid;
        grid-template-columns: minmax(0, 1fr);
        align-content: start;
        row-gap: 42px;
        width: calc((var(--column-single-width) * var(--columns-inner-count)) + (var(--column-gap) * (var(--columns-inner-count) - 1)));

        .menu-group-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 20px;

          a {
            color: $secondary-color;
            text-decoration: none;
          }
          .group-title-wrp {
            .group-title {
              font-weight: 700;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0px;
            }
          }
          .group-items-wrp {
            display: grid;
            grid-template-columns: repeat(var(--columns-inner-count), minmax(0, 1fr));
            align-content: start;
            row-gap: 7px;
            column-gap: var(--column-gap);

            a {
              display: flex;
              justify-content: start;
              align-items: start;
              column-gap: 10px;

              .item-icon {
                margin-top: 0px;

                &.icon-after {
                  //margin-left: 10px;
                }
              }
            }
            ul {
              list-style: none;
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              align-content: start;
              row-gap: 20px;
              margin: 0px;
              padding: 0px;

              li {
                display: flex;
                justify-content: start;
              }
            }
          }
        }

        &.columns-2 {
          --column-size: 2;
          --columns-inner-count: 2;
        }
        &.columns-3 {
          --column-size: 3;
          --columns-inner-count: 3;

        }
        &.columns-4 {
          --column-size: 4;
          --columns-inner-count: 4;
        }
        &.columns-5 {
          --column-size: 5;
          --columns-inner-count: 5;
        }
      }
    }
    &.open {
      max-height: 90vh;
    }
    &.in-action {
      transition: max-height .3s;
    }
  }
}