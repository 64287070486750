
#newsListing {
  .latest-post {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 24px;
    margin-bottom: 56px;

    .photo-wrp {
      position: relative;

      .news-tags-wrp {
        position: absolute;
        top: 43px;
        left: 23px;
        z-index: 1;
      }
      a {
        img {
          width: 100%;
          border-radius: 24px;
        }
      }
    }
    .body-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      align-content: start;
      justify-items: start;

      .article-category {
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 19px;
        color: $gray-color;
      }
      .article-title {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
        letter-spacing: 0px;
        font-family: Borna;
      }
      .btn {

      }
    }
  }
  .filters-wrp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;

    a {
      padding: 8px 10px;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0px;
      text-decoration: none;
      background-color: $violet-light-color;
      border-radius: 12px;
      transition: background-color 0.3s;

      &:hover {
        background-color: $violet-medium-color;
      }
      &.active {
        background-color: $violet-color;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .latest-post {
      grid-template-columns: minmax(0, 10fr) minmax(0, 12fr);
      align-items: center;
      column-gap: 20px;
    }
  }
  @include media-breakpoint-up(lg) {
    .latest-post {
      grid-template-columns: minmax(0, 16fr) minmax(0, 12fr);
      column-gap: 64px;

      .photo-wrp {
        .news-tags-wrp {
          top: 53px;
          left: 33px;
        }
      }
      .body-wrp {
        .article-title {
          margin-bottom: 32px;
          font-size: 28px;
          line-height: 32px;
        }
      }
    }
  }
}
.news-listing {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 32px;

  .news-element {
    display: grid;
    grid-template-columns: 108px minmax(0, 1fr);
    column-gap: 16px;

    .photo-wrp {
      display: grid;
      align-items: start;

      a {
        overflow: hidden;
        border-radius: 14px;
      }
    }
    .body-wrp {
      display: flex;
      flex-direction: column;

      .article-title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: $secondary-color;
        letter-spacing: 0px;
      }
      .article-category {
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        color: $gray-color;
      }
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 40px;
    column-gap: 20px;

    .news-element {
      grid-template-columns: minmax(0, 1fr);
      row-gap: 16px;

      .body-wrp {
        .article-title {
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

#news_detailed {

  .content-grid {
    --content-small-max-width: 620px;
    --content-wide-max-width: 820px;
  }
  .news-header {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    justify-items: start;
    margin-top: 8px;
    margin-bottom: 32px;

    .return-wrp {
      margin-bottom: 22px;

      a {
        padding: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-decoration: none !important;
      }
    }
    .title {
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 32px;
    }
    .other-data-wrp {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      font-size: 13px;
      line-height: 19px;
    }
  }
  .page-editor-type-text {
    grid-column: content-small;
    letter-spacing: -0.4px;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  a:not(.btn) {
    color: $secondary-color;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}