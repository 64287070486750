$grid-columns:          12;
//$grid-gutter-width:           1.5rem !default;
$grid-row-columns:            6 !default;
@include make-grid-columns-project($grid-columns, $grid-gaps, $grid-breakpoints);

.lh-0 {
  line-height: 0px;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.custom-scrollbar {
  @include custom-scrollbar();
}
.add_to_wishlist {
  .ci-heart-filled {
    display: none;
  }

  &.active {
    .ci-heart {
      display: none;
    }
    .ci-heart-filled {
      display: block;
    }
  }
}

.widget-categories {
  .accordion-header {
    &.no-childs {
      .accordion-button:after {
        display: none;
      }
    }
  }
}

.tags {
  .badge {
    margin-bottom: 4px;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-color;
    background-color: var(--badge-color);
    backdrop-filter: blur(4px);
    border-radius: 10px;

    img {
      max-width: 30px;
    }
    &.tag-image {
      padding: 0px;
    }
  }
}
.discount-custom-tag, .news-custom-tag {
  position: relative;

  > span {
    display: flex;
    column-gap: 8px;
    padding: 8px 12px;
    font-family: Borna;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0px;
    background-color: #95FF8C;
    border-radius: 28px;
    transform: rotate(-15.41deg) translateY(-18px);

    i {
      font-size: 20px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $secondary-color;
    border-radius: 28px;
    transform: scale(0.8);
  }
}
.news-custom-tag {
  &:before {
    top: 7px;
    left: 10px;
    width: 106%;
    height: 86%;
    background-color: #E3FF8C;
    transform: scale(0.8) rotate(5.03deg);
  }
  > span {
    padding: 9px 16px;
    font-size: 21px;
    line-height: 24px;
    background-color: #E9BDFF;
    transform: rotate(-9.08deg) translateY(-18px);
  }
}

#ajax_loader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);

  .load-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    animation: c 1.8s linear infinite;
    -webkit-animation: c 1.8s linear infinite;

    &:before, &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-radius: 20px;
      background: hsla(0, 0%, 100%, 0.9);
    }
    &:before {
      left: 0;
      animation: d 1.8s linear infinite;
      -webkit-animation: d 1.8s linear infinite;
    }
    &:after {
      right: 0;
      animation: e 1.8s linear infinite;
      -webkit-animation: e 1.8s linear infinite;
      animation-delay: -0.9s;
      -webkit-animation-delay: -0.9s;
    }
  }
}

#searchCarousel {
  @include media-breakpoint-up(md) {
    .product-card {
      max-width: 300px;
    }
  }
}

.navbar-side-menu {
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 100%;
  list-style: none;
  border-color: $dropdown-bg;
  background-color: $dropdown-bg;
  box-shadow: $dropdown-box-shadow;

  a {
    &:hover {}
  }
  .mega-dropdown-column {
    width: $navbar-mega-dropdown-column-width;
  }
  > .dropdown {
    > a {
      padding: 0.5rem 1.95rem 0.5rem 1.3rem;
      color: #495057;
      border-bottom: 1px solid #e3e9ef;
      white-space: initial;

      &:after {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: rotate(-90deg) translateY(-50%);
        transform-origin: top;
      }
    }
    .dropdown-menu {
      display: none;
      top: .375rem !important;
      right: auto !important;
      left: 100% !important;
      margin: {
        top: -$dropdown-padding-y !important;
        right: 0 !important;
        left: -.25rem !important;
      }
      min-height: calc(100% + .25rem);
      width: auto;
      animation: none !important;
      transform: initial !important;

      &.product-line::before {
        position: absolute;
        top: 0;
        right: $navbar-mega-dropdown-column-width + $spacer;
        width: $border-width;
        height: 100%;
        background-color: $border-color;
        content: '';
      }
    }
    &:hover {
      & > .dropdown-item {
        color: $primary;
      }
      & > .dropdown-menu {
        display: block;
      }
    }
    &:last-child {
      > a {
        border-bottom: none;
      }
    }
  }
}

.filters-table {
  margin-top: 30px;

  .block-title {}
  .filters-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    li {
      display: grid;
      justify-content: space-between;
      grid-template-columns: auto minmax(0, 2fr);
      column-gap: 40px;
      margin: 0px;
      padding: 10px 0px;
      border-top: 1px solid #e3e9ef;

      span {
        &.value {
          text-align: right;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .filters-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 40px;
    }
  }
}

.destination-picker {
  .opt-group {

  }
  .opt-child {
    display:none;
  }
}

.input-number-group {
  display: grid;
  grid-template-columns: 42px minmax(0, 1fr) 42px;
  height: 48px;
  border: 1px solid #D6D6D6;
  border-radius: 12px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: $secondary-color;
    transition: all .3s;

    &:not(.disabled) {
      cursor: pointer;
    }
    &.disabled {
      opacity: .5;
    }
  }
  input {
    text-align: center;
    border: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.radio-group {
  &.style-1 {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    justify-self: start;
    column-gap: 4px;
    margin-bottom: 20px;
    padding: 4px;
    background-color: $violet-light-color;
    border-radius: 12px;

    label {
      padding: 9px 16px;
      font-size: 15px;
      line-height: 18px;
      color: $secondary-color;
      text-align: center;
      border-radius: 10px;
      cursor: pointer;
      transition: all .3s;

      input {
        display: none;
      }

      &:has(input:checked) {
        font-weight: 500;
        background-color: #ffffff;
        box-shadow: 0px 5.08px 16.25px -2.03px rgba(24, 39, 75, 0.40);
        box-shadow: 0px 3.05px 7.11px -3.05px rgba(77, 67, 120, 0.40);
      }
    }
  }
}

.dp__main[data-theme="theme-1"] {
  .dp__button_bottom, .dp__arrow_top, .dp__arrow_bottom {
    display: none;
  }
  .dp__active_date {
    color: #ffffff;
    background-color: $secondary-color;
  }
  .dp__month_year_wrap {
    .dp__overlay_cell_pad {
      color: $secondary-color;
      background-color: $violet-light-color;
      border-radius: 12px;

      &:hover {
        background-color: $violet-medium-color;
      }
      &.dp__overlay_cell_active {
        background-color: $violet-color;
      }
      &.dp__overlay_cell_disabled {
        background-color: #d0d0d0;
        opacity: .5;
      }
    }
  }
}
.ui-datepicker.year-month-datepicker {
  padding: 24px 20px 30px 20px;
  font-family: Inter;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 2px 8px 0px #00000026;

  .ui-datepicker-header {
    margin-bottom: 20px;
    padding: 0px;
    background: transparent;
    border: none;

    a {
      color: $secondary-color;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
    }
    .ui-datepicker-prev, .ui-datepicker-next {
      top: 2px;
      width: 25px;
      height: 25px;
      background: transparent;
      border: none;

      &:before {
        display: block;
        width: 25px;
        height: 25px;
        font-size: 16px;
        line-height: 25px;
        font-family: FontAwesome;
        color: $secondary-color;
      }
      span {
        background: none;
        background-image: none;
      }
    }
    .ui-datepicker-prev {
      left: 2px;

      &:before {
        content: "\f053";
      }
    }
    .ui-datepicker-next {
      right: 2px;

      &:before {
        content: "\f054";
      }
    }
  }
  .ui-datepicker-select-year, .ui-datepicker-select-month {
    table {
      td {
        padding: 3px;

        .ui-state-default {
          display: block;
          padding: 16px 4px;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
          color: $secondary-color;
          background-color: $violet-light-color;
          border: none;
          border-radius: 12px;
          transition: all .3s;

          &:hover {
            background-color: $violet-medium-color;
          }
          &.ui-state-active {
            background-color: $violet-color;
          }
        }
      }
    }
  }
  .ui-datepicker-calendar {
    th {
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #717184;
      }
    }
    td {
      .ui-state-default {
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1 / 1;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0;
        border: none;
        background: none;
        border-radius: 50%;
        transition: background-color .3s, color .3s;


        &.ui-state-highlight {
          font-weight: 600;
          color: $secondary-color;
        }
        &.ui-state-hover {
          background-color: $violet-light-color;
        }
        &.ui-state-active {
          font-weight: 600;
          color: #ffffff;
          background-color: $secondary-color;
        }
      }
    }
  }
}

.product-card {
  .active-star {
    color: var(--bs-yellow);
  }
}

.no-offers-wrp {
  margin-bottom: 28px;

  .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    min-height: 300px;
    text-align: center;

    .title {
      margin-bottom: 8px;
      font-family: Borna;
      font-weight: 600;
      font-size: 24px;
      line-height: 27px;
      letter-spacing: 0px;
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    .title {
      font-size: 28px;
      line-height: 36px;
    }
  }
}

.photos-gallery {
  margin-bottom: 32px;

  .main-group-wrp {
    position: relative;

    .preview-wrp {
      .photo:first-child {
        position: relative;

        .tags {
          position: absolute;
          top: 16px;
          left: 16px;
        }
        .tags-bottom {
          position: absolute;
          bottom: 48px;
          right: 12px;
          z-index: 2;
          display: flex;
          flex-direction: row;
          align-items: end;
          gap: 8px;
          pointer-events: none;


          .badge {
            &.no-bg {
              padding: 0px;

              img {
                max-width: 128px;
              }
            }
          }
        }
      }
    }
    .photos-count-wrp {
      position: absolute;
      right: 16px;
      bottom: 16px;
      padding: 5px 10px;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
      border-radius: 32px;

      background: #11111166;
      backdrop-filter: blur(4px);
      pointer-events: none;
    }
  }
  .gallery-wrp {
    position: fixed;
    z-index: 9999;
    inset: 0;
    display: none;
    grid-template-rows: auto minmax(0, 1fr);
    background-color: #ffffff;

    .header-block {
      border-bottom: 1px solid #EBEBEB;

      .inner-wrp {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0px;

        .act-toggle-gallery {
          font-size: 12px;
          line-height: 18px;
          color: $secondary-color;
          cursor: pointer;
        }
      }
    }
    .content-block {
      overflow: auto;

      .inner-wrp {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 4px;
        padding: 20px 0px;

        .photo-el {
          display: block;
          overflow: hidden;
          border-radius: 4px;

          &:nth-child(3n + 3) {
            grid-column: span 2;
          }
        }
      }
    }
    &.active {
      display: grid;
    }
  }

  @include media-breakpoint-down(lg) {
    grid-column: full-width !important;

    .main-group-wrp {

      .preview-wrp {
        .photo {
          &.small {
            display: none;
          }
          &:first-child {
            .tags-bottom, .tags {
              > span {
                &:not(:first-child) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 64px;

    .main-group-wrp {
      .preview-wrp {
        display: grid;
        grid-template-columns: minmax(0, 622fr) repeat(2,  minmax(0, 300fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
        gap: 8px;

        .photo {
          overflow: hidden;
          border-radius: 14px;

          &:first-child {
            grid-row: span 2;

            .tags-bottom {
              bottom: 12px;
            }
          }
        }
      }
    }
    .gallery-wrp {
      .header-block {
        .inner-wrp {
          .act-toggle-gallery {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .content-block {
        .inner-wrp {
          padding: 32px 0px;
          gap: 8px;

          .photo-el {
            border-radius: 14px;
          }
        }
      }
    }
  }
}

.hotel-stars {
  display: flex;
  flex-direction: row;

  i {
    font-size: 16px;
    color: #FDEE6B;
  }
}

.best-travel-option {
  .top-badge {
    position:absolute;
    top:-15px;
  }
}

.plisting-grid-3 {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 16px;
  row-gap: 24px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.plisting-grid-2 {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 16px;
  row-gap: 24px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.swiper-pagination {
  line-height: 0px;

  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background-color: #D6D6D6;
    border-radius: 10px;
    transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      width: 20px;
      background-color: $secondary-color;
    }
  }
}

.swiper-nav-btn {
  width: 40px !important;
  height: 40px !important;
  font-size: 14px;
  color: $secondary-color !important;
  background-color: #ffffff;
  border-radius: 20px;

  &:after {
    display: none;
  }
}

.newsletter-form-wrp {
  .tags-wrp {
    display: flex;
    flex-display: column;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;

    .tag {
      padding: 5px 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $secondary-color;
      white-space: nowrap;
      background-color: #B5E0FF;
      border-radius: 12px;
    }
  }
  .title {
    display: block;
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }
  .fields-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    > .btn {
      margin-top: 16px;
    }
  }
}

.page-title-block {
  margin-bottom: 64px;

  .page-title {
    margin-bottom: 16px;
    color: $secondary-color;
  }
  .top-description-wrp {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #333446;
  }
}
.bottom-description-wrp {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1030px;
  width: 100%;
}

.help-block {
  container-name: help-container;
  container-type: inline-size;

  .inner-wrp {
    overflow: hidden;
    display: grid;
    grid-template-columns: 56px minmax(0, 1fr) 44px;
    grid-template-areas: "photo photo photo"
                         "text text actions";
    column-gap: 20px;
    row-gap: 16px;
    padding: 16px;
    background-color: $violet-color;
    background-image: url('/public/images/frontend/help-bg.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 62px);
    background-size: auto 100%;
    border-radius: 16px;

    .photo-wrp {
      grid-area: photo;
      justify-self: start;

      .person-wrp {
        position: relative;

        img {
          height: 54px;
          width: 54px;
          border-radius: 27px;
          object-fit: cover;
        }
        &:before {
          content: '';
          position: absolute;
          right: -2px;
          bottom: -2px;
          display: block;
          height: 14px;
          width: 14px;
          background-color: #95FF8C;
          border-radius: 7px;
          box-shadow: 0px 0px 0px 4px $violet-color;
        }
      }
      .indicator {}
    }
    .text-wrp {
      grid-area: text;
      display: grid;
      grid-template-columns: minmax(0, 1fr);

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0px;
        color: $secondary-color;
      }
      .slogan {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0px;
        color: $secondary-color;
      }
    }
    .actions-wrp {
      grid-area: actions;

      .icon-wrp {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        font-size: 18px;
        color: $secondary-color;
        background-color: $primary-color;
        border-radius: 22px;
      }
    }
  }
  @container help-container (width > 400px) {
    .inner-wrp {
      grid-template-areas: "photo text actions";
      grid-template-columns: 66px minmax(0, 1fr) 56px;
      align-items: center;
      background-position: calc(100% - 90px);
      border-radius: 24px;

      .photo-wrp {
        .person-wrp {
          img {
            height: 64px;
            width: 64px;
            border-radius: 32px;
          }
        }
      }
      .text-wrp {

      }
      .actions-wrp {
        .icon-wrp {
          width: 56px;
          height: 56px;
          border-radius: 28px;
        }
      }
    }
  }
}

#helpDrawer {
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: 24px 16px;

        .photo-wrp {}
        .contacts-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 24px;

          .contacts-title {
            font-weight: 600;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0px;
          }
          .contacts-list {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 8px;
          }
        }
      }
    }
  }
}
#contactUsModal {

  .thanks-container {
    text-align: center;

    .icon-wrp {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        font-size: 26px;
        border-radius: 32px;
        background-color: #E3FF8C;
      }
    }
    .text-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 12px;

      .title {
        font-family: Borna;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0px;
      }
      .desc {
        margin: 0px;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-body {
      padding: 24px 32px 32px 32px;

      .thanks-container {
        .text-wrp {
          .title {
            font-size: 40px;
            line-height: 48px;
          }
          .desc {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

.loader-small-circle {
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px #F1F1F1;
  animation: rotate 1s linear infinite;

  &::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    inset: 0px;
    border-radius: 50%;
    border: 4px solid $secondary-color;
    animation: prixClipFix 2s linear infinite ;
  }
}

.loader-large-circle {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 6px #F1F1F1;
  animation: rotate 1s linear infinite;

  &::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    inset: 0px;
    border-radius: 50%;
    border: 6px solid $secondary-color;
    animation: prixClipFix 2s linear infinite ;
  }
}

.elements-pagination {
  margin-bottom: 80px;

  .pagination {
    .page-item {
      .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        min-width: 44px;
        font-size: 16px;
        line-height: 24px;
        color: $secondary-color;
        border: 1px solid #D6D6D6;
        border-radius: 12px;
        box-shadow: none !important;
        transition: all .3s;

        &.disabled {
          opacity: 0.5;
        }
      }
      &.active {
        .page-link {
          color: #ffffff;
          border-color: $secondary-color;
          background-color: $secondary-color;
        }
      }
    }
  }
}