#search-results {
  .travel-option-card {
    .inner-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);

      .info-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 20px;

        .trip-details {
          .dates-wrp {
            display: flex;
            flex-direction: row;
            align-items: center;

            .date {
              font-size: 1.1rem;
              white-space: nowrap;
            }
            svg {
              flex-shrink: 0;
              margin: 0px 12px;
            }
          }
          .flight-info-drop {
            .list-unstyled {
              li {
                &.flight-time {
                  display: grid;
                  grid-template-columns: auto minmax(0, 1fr);
                  align-items: center;
                }
              }
            }
          }
        }
        .features-wrp {
          list-style: none;
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          margin: 0px;
          padding: 0px;
          font-size: .875rem;

          li {
            a {
              color: var(--bs-body-color);
              text-decoration: underline;
            }
          }
        }
      }
      .pricing-wrp {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.085);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .travel-option-card {
      .inner-wrp {
        grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);

        .info-wrp {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          row-gap: 0px;

          .features-wrp {
            flex-direction: row;
            column-gap: 32px;
            margin-top: 32px;
          }
        }
        .pricing-wrp {
          justify-content: space-between;
          margin-top: 0px;
          padding-top: 0px;
          padding-left: 1.25rem;
          text-align: right;
          border-top: none;
          border-left: 1px solid rgba(0, 0, 0, 0.085);
        }
      }
    }
  }
}