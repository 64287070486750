#destinationsPage {
  //overflow-x: hidden;

  .html_content {
    > .editor_type_text {
      padding: 0px;
      max-width: 505px;
      margin-left: 0px !important;
    }
  }
  .block-title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 27px;
  }
  .destination-item {
    overflow: hidden;
    border-radius: 14px;

    a {
      position: relative;
      display: block;
      height: 100%;

      .texts-wrp {
        position: absolute;
        inset: 0;
        display: grid;
        align-content: end;
        justify-content: start;
        justify-items: start;
        row-gap: 16px;
        padding: 20px;
        background: linear-gradient(18.8deg, rgba(16, 37, 66, 0.6) 11.61%, rgba(16, 37, 66, 0) 59.8%);

        .title {
          font-weight: 600;
          font-size: 24px;
          line-height: 27px;
          letter-spacing: 0;
          font-family: Borna;
          color: #ffffff;
        }
        .price {
          padding: 8px 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0;
          white-space: nowrap;
          background-color: #E3FF8C;
          border-radius: 12px;
        }
      }
    }
  }
  .top-destinations-wrp {
    margin-bottom: 64px;

    .swiper {
      overflow: visible;

      .swiper-wrapper {
        .swiper-slide {
          &:nth-child(5n+1) .price {  background-color:  #E3FF8C;}
          &:nth-child(5n+2) .price { background-color:  #CFC6FC;}
          &:nth-child(5n+3) .price { background-color:  #FFF06B;}
          &:nth-child(5n+4) .price { background-color:  #E9BDFF;}
          &:nth-child(5n+5) .price { background-color:  #B5E0FF;}
        }
      }
      &:not(.swiper-initialized) {
        .swiper-wrapper {
          display: flex;
          flex-direction: row;
          column-gap: 8px;

          .swiper-slide {
            width: 63.4%;
          }
        }
      }
    }
  }
  .all-destinations-wrp {
    container-name: all-dest-container;
    container-type: inline-size;
    margin-bottom: 64px;

    .items-listing {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 12px;

      .destination-item {
        a {
          .texts-wrp {
            justify-content: initial;
            background: linear-gradient(221.35deg, rgba(16, 37, 66, 0) 41.92%, rgba(16, 37, 66, 0.6) 92.45%);

            .texts-inner-wrp {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              column-gap: 16px;
              width: 100%;
              align-items: center;
            }
          }
        }
        &:nth-child(5n+1) .price {  background-color:  #E3FF8C;}
        &:nth-child(5n+2) .price { background-color:  #CFC6FC;}
        &:nth-child(5n+3) .price { background-color:  #FFF06B;}
        &:nth-child(5n+4) .price { background-color:  #E9BDFF;}
        &:nth-child(5n+5) .price { background-color:  #B5E0FF;}
      }
    }
    @container all-dest-container (width > 650px) {
      .items-listing {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
    @container all-dest-container (width > 992px) {
      .items-listing {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }

  @media (min-width: 430px) {
    .top-destinations-wrp {
      .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
        width: 38.4%;
      }
    }
  }
  @media (min-width: 600px) {
    .top-destinations-wrp {
      .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
        width: 27.4%;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .block-title {
      font-size: 28px;
      line-height: 32px;
    }
    .destination-item {
      a {
        .texts-wrp {
          font-size: 28px;
          line-height: 32px;
        }
        .price {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
    .top-destinations-wrp {
      .swiper:not(.swiper-initialized) .swiper-wrapper {
        column-gap: 16px;

        .swiper-slide {
          width: calc(20% - 14px);
        }
      }
    }
    .all-destinations-wrp {
      .items-listing {
        gap: 16px;
      }
    }
  }
}