@mixin make-grid-col-auto() {
  grid-template-columns: auto;
}
@mixin grid-cols($count) {
  grid-template-columns: repeat($count, minmax(0, 1fr));
}
@mixin grid-spans($count) {
  grid-column: $count span;
}
@mixin grid-cgap($size) {
  column-gap: $size;
}
@mixin grid-rgap($size) {
  row-gap: $size;
}

@mixin make-grid-columns-project($columns: $grid-columns, $gaps, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @if $columns > 0 {
        @for $i from 1 through $columns {
          .grid-spans#{$infix}-#{$i} {
            @include grid-spans($i);
          }
          .grid-cols#{$infix}-#{$i} {
            @include grid-cols($i);
          }
        }
      }
      @each $key, $value in $gaps {
        .grid-cgap#{$infix}-#{$key} {
          @include grid-cgap($value);
        }
        .grid-rgap#{$infix}-#{$key} {
          @include grid-rgap($value);
        }
      }
    }
  }
}

@mixin fontello-icon($content) {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  //speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  line-height: inherit;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

  content: $content;
}

@mixin shimmer-bg($color: #EEF2F6, $duration: 2.5s) {
  animation: shimmer $duration linear infinite;
  background: linear-gradient(to right, $color 0%, $color 40%, rgba(255, 255, 255, 0.2) 50%, $color 60%);
  background-size: 200% 100%;
}

@mixin custom-scrollbar() {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #D9D9D9;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4;
  }
}

@mixin flights-table() {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #EBEBEB;

  .flights-list-wrp {
    .single-flight-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 18px;

      .flight-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 16px;
        font-size: 15px;
        line-height: 24px;

        .name {
          display: grid;
          grid-template-columns: 24px minmax(0, 1fr);
          column-gap: 12px;
          align-items: center;

          i {
            font-size: 18px;
          }
        }
      }
    }
  }
  &.no-border {
    border-bottom: none;
  }
}

@mixin dates-options-wrp-desktop() {
  padding-top: 20px;
  padding-bottom: 20px;

  .date-type-tabs {
    justify-self: center;
    margin-bottom: 22px;

    span {
      min-width: 138px;
    }
  }
  .select-by-types-wrp {
    padding: 0px 20px;

    .select-by-date-wrp {
      .calendar-wrp {
        > .dp__main {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
        }
        .dp__menu {
          .dp__menu_inner {
            column-gap: 18px;

            .dp__instance_calendar {
              .dp--header-wrap {
                .dp__month_year_wrap {
                  .custom-month-year-component {
                    position: relative;
                    font-size: 16px;

                    .months-change-icon {
                      position: absolute;
                      top: 0px;
                      cursor: pointer;

                      &.month-prev {
                        left: 12px;
                      }
                      &.month-next {
                        right: 12px;
                      }
                    }
                  }
                }
              }
              &:first-child .custom-month-year-component .months-change-icon.month-next {
                display: none;
              }
              &:last-child .custom-month-year-component .months-change-icon.month-prev {
                display: none;
              }
            }
          }
        }
      }
      .flex-days-wrp {
        border-top: none;
      }
    }
    .select-by-month-wrp {
      .year-groups-wrp {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 40px;

        .year-group {
          align-content: start;
          row-gap: 18px;

          .year-title {
            font-size: 16px;
            text-align: center;
          }
          .months-wrp {
            .month-item {
              min-height: 56px;
            }
          }
        }
      }
    }
  }
}
@mixin dates-options-wrp-mobile () {
  .select-by-types-wrp {
    .calendar-wrp {
      > .dp__main {
        > div:has(.dp__outer_menu_wrap) {
          width: 100%;
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .dp__menu {
        .dp__menu_inner {
          flex-direction: column;
        }
      }
    }
  }
}