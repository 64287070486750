// FOR COLORS DO NOT OVERRIDE $theme-colors ARRAY
// OVERRIDE VARIABLES cartzilla/_variables.scss 28-36 lines

$border-radius: 0.3125rem !default;

$primary-color: #FFF06B;
$secondary-color: #191B46;
$violet-light-color: #F1EEFE;
$violet-medium-color: #E8E3FF;
$violet-color: #CDC4FA; // #CFC6FC
$gray-color: #717184;

$font-size-base: 1rem;
$line-height-base: 1.5;

$icons-font-path: '../../../public/fonts/cartzilla';

$enable-grid-classes: true !default;

// lightGallery
$lg-path-fonts: '../../fonts/lg'!default;

$grid-gaps: (
        6: 6px,
        8: 8px,
        12: 12px,
        14: 14px,
        16: 16px,
        18: 18px,
        20: 20px,
        22: 22px,
        32: 32px,
        48: 48px,
        56: 56px,
        96: 96px,
);

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: 6px,
        8: 8px,
        12: 12px,
        14: 14px,
        15: 15px,
        16: 16px,
        17: 17px,
        18: 18px,
        19: 19px,
        20: 20px,
        22: 22px,
        24: 24px,
        25: 25px,
        26: 26px,
        28: 28px,
        30: 30px,
        32: 32px,
        33: 33px,
        34: 34px,
        35: 35px,
        36: 36px,
        38: 38px,
        40: 40px,
        42: 42px,
        44: 44px,
        48: 48px,
        50: 50px,
        54: 54px,
        56: 56px,
        60: 60px,
        64: 64px,
        66: 66px,
        70: 70px,
        72: 72px,
        75: 75px,
        80: 80px,
        88: 88px,
        96: 96px,
        120: 120px,
        128: 128px
);