#products_detailed {
  --narrow-content-width: 1030px;
  --side-gap: 16px;

  .product-top-part {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 32px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    max-width: var(--narrow-content-width);

    .offer-intro-wrp {
      .product-title {
        margin-bottom: 20px;
        font-family: 'Inter', sans-serif;
        letter-spacing: 0.3px;
      }
      .location-wrp {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 32px;

        .location-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $secondary-color;

          a {
            color: $secondary-color;
          }
        }
        .location-stars {

        }
      }
      .content-links-wrp {
        margin-bottom: 26px;

        .inner-wrp {
          display: flex;
          flex-direction: row;
          gap: 6px;

          .content-link-btn {
            padding: 11px 10px;
            font-size: 15px;
            line-height: 24px;
            color: $secondary-color;
            white-space: nowrap;
            letter-spacing: 0.5px;
            background-color: $violet-light-color;
            border: none;
            border-radius: 12px;
            transition: all .3s;

            &:hover {
              background-color: $violet-medium-color;
            }
          }
        }
      }
      .hotel-desc {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.1px;

        p {
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .recommendations-wrp {
      .recommendation-el {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 12px;
        padding: 32px 24px 24px 24px;
        background-color: #B5E0FF;
        border-radius: 24px;

        .recommendation-header {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 96px);
          column-gap: 16px;

          .title-wrp {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
          }
          .photo-wrp {
            position: relative;
            display: grid;
            justify-content: end;

            .recommend-badge {
              position: absolute;
              top: 50%;
              left: 0px;
              z-index: 1;
              transform: translateY(calc(-50% - 8px));
            }
            .person-photo {
              margin-top: -16px;
              width: 64px;
              height: 64px;
              border-radius: 32px;
            }
          }
        }
        .desc-wrp {
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;

          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .product-search-part {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 65px;
    max-width: var(--narrow-content-width);

    .block-title {
      margin-bottom: 24px;
    }
    .search-filters-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 16px;
      margin-bottom: 26px;

      .search-form-wrp {
        .filters-listing {
          > .inner-wrp {
            display: flex;
            flex-direction: row;
            column-gap: 6px;

            .filter-group-wrp {
              .filter-group-title {
                margin: 0px;
                padding: 11px 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0px;
                white-space: nowrap;
                background-color: $violet-light-color;
                border-radius: 12px;
                cursor: pointer;
                transition: background-color .3s;

                &:after {
                  content: '\f078';
                  display: inline-block;
                  margin-left: 12px;
                  font-size: 16px;
                  font-family: FontAwesome;
                  transition: transform .3s;
                }
                &:hover {
                  background-color: $violet-medium-color;
                }
              }
              .filter-group-items {
                display: none;

                > .inner-wrp {
                  padding: 16px;
                  overflow: auto;

                  .nights-options-wrp {
                    padding-top: 0px;
                  }
                  .passengers-options-wrp {
                    padding-top: 0px;
                  }
                  > ul {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                    margin: 0px;
                    padding: 0px;

                    li {
                      .filter-check {
                        label {
                          padding: 6px 0px;
                        }
                      }
                    }
                  }
                }
              }
              &.open {
                .filter-group-title {
                  &:after {
                    transform: rotate(-180deg);
                  }
                }
              }
            }
          }

        }
        .filters-loading {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 6px;

          span {
            @include shimmer-bg(#F1EEFE);

            height: 48px;
            border-radius: 12px;

            &:nth-child(1) { width: 74px; }
            &:nth-child(2) { width: 185px; }
            &:nth-child(3) { width: 110px; }
            &:nth-child(4) { width: 114px; }
          }
        }
      }
      .filters-wrp {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 6px;

        button {
          display: flex;
          column-gap: 10px;
        }
      }
    }
    .offers-loading-wrp {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      gap: 12px;
      margin: 0px -16px 28px -16px;
      padding: 0px 16px;

      span {
        @include shimmer-bg(#F1EEFE);
        flex-shrink: 0;
        display: block;
        height: 440px;
        width: 83%;
        border-radius: 24px;
      }
    }
    .offers-wrp {
      .offers-listing {
        position: relative;
        margin-bottom: 28px;

        .swiper {
          .swiper-wrapper {
            display: grid;
            grid-auto-flow: column;
            justify-content: start;
          }
          &:not(.swiper-initialized) {
            .swiper-wrapper {
              display: flex;
              flex-direction: row;
              column-gap: 12px;

              .swiper-slide {
                width: calc((100% / 1.2) - ((16px) / 2));
              }
            }
          }
        }
        .swiper-nav-btn {
          display: none;
          width: 48px !important;
          height: 48px !important;
          font-size: 18px;
          border-radius: 24px;
          box-shadow: 0px 3px 12px -3px rgba(24, 39, 75, 80%);


          &.swiper-button-next {
            right: -32px;
          }
          &.swiper-button-prev {
            left: -32px;
          }
        }
        .offer-card {
          display: flex;
          flex-direction: column;
          padding: 16px;
          height: 100%;
          overflow: hidden;
          background-color: $violet-light-color;
          border-radius: 24px;

          .tags-wrp {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 4px;
            margin-bottom: 26px;

            .tag {
              padding: 5px 10px;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              border-radius: 10px;
              background-color: #E3FF8C;

              &.tag-recommend {
                background-color: #B5E0FF;
              }
            }
          }
          .price-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 5px;
            margin-bottom: 30px;

            .person-price-wrp {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 8px;
              align-items: end;

              .price {
                font-weight: 600;
                font-size: 36px;
                line-height: 36px;
                font-family: Borna;
              }
              .old-price {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: $gray-color;
                text-decoration: line-through;
                text-decoration-line: line-through;
              }
              .price-prefix {}
            }
            .total-price-wrp {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
            }
          }
          .details-wrp {
            flex-grow: 1;
            margin-bottom: 22px;

            .round-checkmark-list {
              row-gap: 12px;

              li {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
              }
            }
            .btn-more {
              color: $secondary-color;
              text-decoration: none !important;

              i {
                margin-right: 10px;
                font-size: 17px;
              }
              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                text-decoration: underline;
              }
              &:hover {
                span {
                  text-decoration: none;
                }
              }
            }
          }
          .travel-dates-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            margin-bottom: 28px;

            .dates {
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
            }
            .flights-info {
              .info-collapsed {
                .btn-flights {
                  margin-right: 4px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 18px;
                  text-decoration: underline;
                  color: $secondary-color;

                  &:hover {
                    text-decoration: none;
                  }
                }
                i {
                  font-size: 15px;
                }
              }
              .info-expanded {
                display: grid;
                grid-template-columns: minmax(0, 1fr);
                row-gap: 14px;
                margin-top: 14px;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;

                .flight-stage {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
        }
      }
      .offers-warnings {

      }
    }
  }

  .offer-details-modal {
    --block-title-weight: 500;
    --block-title-size: 16px;
    --block-title-line-height: 22px;

    .block-title {
      margin-bottom: 0px;
      font-weight: var(--block-title-weight);
      font-size: var(--block-title-size);
      line-height: var(--block-title-line-height);

      &.big {
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
      }
    }

    .modal-body {
      padding: 16px 16px 98px 16px;

      .offer-summary-wrp {
        margin-bottom: 32px;
        padding: 20px 20px 22px 20px;
        background-color: $violet-light-color;
        border-radius: 24px;

        .summary-top {
          display: grid;
          grid-template-columns: minmax(0, 79px) minmax(0, 1fr);
          column-gap: 12px;
          margin-bottom: 16px;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(25, 27, 70, 0.2);

          .photo-wrp {
            img {
              border-radius: 14px;
            }
          }
          .info-wrp {
            display: grid;
            align-content: center;

            .name {
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
            }
            .location {
              font-size: 13px;
              line-height: 19px;
            }
          }
        }
        .summary-bottom {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 18px;

          .price-wrp {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 4px;

            .person-price-wrp {
              .price {
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
              }
              .old-price {
                font-size: 12px;
                line-height: 16px;
                color: $gray-color;
                text-decoration: line-through;
                text-decoration-line: line-through;
              }
              .price-prefix {
                margin-left: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
              }
            }
            .total-price-wrp {
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;

              .price-prefix {
                margin-left: 4px;
                font-weight: 500;
              }
            }
            .advance-price-wrp {
              font-size: 15px;
              line-height: 24px;
              letter-spacing: 0px;
            }
          }
          .travel-dates-wrp {
            .dates {
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
            }
          }
        }
        .actions-wrp {
          margin-top: 12px;
        }
      }
      .room-photos-wrp {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 8px;
        margin-bottom: 40px;

        .photo-el {
          img {
            border-radius: 14px;
          }
          &.big {
            grid-column: span 2;
          }
        }
      }
      .room-details-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 10px;
        margin-bottom: 32px;
        padding-bottom: 26px;
        border-bottom: 1px solid #EBEBEB;

        .features-list-wrp {
          .feature-el {

            i {}
            span {}
          }
        }
      }
      .flights-info-wrp {
        @include flights-table();
      }
      .offer-included-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 25px;
        margin-bottom: 48px;

        ul {
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .order-terms-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 16px;

        .desc-wrp {
          font-size: 15px;
          line-height: 24px;

          p {
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }

      .offer-details-placeholder {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 32px;

        .offer-summary {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 28px;
          height: 309px;
          width: 100%;
          background-color: $violet-light-color;
          border-radius: 24px;

          .loader-large-circle {}
          .text {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0%;
          }
        }
        .other-info {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 15px;
          justify-content: start;
          padding-top: 32px;
          border-top: 1px solid #EBEBEB;

          .placeholder-line {
            display: flex;
            justify-content: start;
            align-items: center;
            column-gap: 10px;

            .circle {
              @include shimmer-bg(#F1F1F1);
              width: 36px;
              height: 36px;
              border-radius: 18px;
            }
            .text {
              @include shimmer-bg(#F1F1F1);
              height: 24px;
              border-radius: 6px;
            }
          }
        }
      }
      .offer-details-expired {
        display: grid;
        justify-items: center;
        align-content: center;
        text-align: center;
        row-gap: 22px;
        min-height: 400px;

        i {
          font-size: 40px;
        }
        p {
          margin-bottom: 2px;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
        .btn {
          min-width: 118px;
        }
      }
    }
  }

  .hotel-details-part, .offer-included-part, .location-part, .help-part {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    max-width: var(--narrow-content-width);

    .block-title {
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 24px;
      line-height: 27px;
      font-family: 'Borna';
    }
  }

  .hotel-details-part {
    .hotel-gallery-wrp {
      .photo-el {
        overflow: hidden;
        display: block;
        border-radius: 14px;
      }
    }
    .hotel-features-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 18px;

      .hotel-features-groups-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 18px;

        .features-group-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 16px;

          .group-title {
            display: flex;
            flex-direction: row;
            column-gap: 8px;

            i {
              width: 24px;
              font-size: 20px;
              text-align: center;
            }
            span {
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
            }
          }
          ul {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            row-gap: 12px;
          }
        }
      }
    }
  }

  .offer-included-part {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    .offer-included-groups-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 24px;

      .offer-included-group {
        ul {
          list-style: none;
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 16px;
          padding-left: 0px;

          li {
            position: relative;
            padding-left: 36px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            &:before {
              content: '\f00c';
              position: absolute;
              top: 0px;
              left: 0px;
              display: block;
              width: 24px;
              height: 24px;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              font-family: FontAwesome;
              background-color: $violet-color;
              border-radius: 12px;
            }
          }
        }

        &.offer-excluded {
          ul {
            li {
              color: $gray-color;
              text-decoration: line-through;

              &:before {
                content: "\f068";
                background-color: #B5E0FF;
              }
            }
          }
        }
      }
    }
  }
  .location-part {
    .location-content-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 20px;

      .address-wrp {
        letter-spacing: 0px;

        i {
          margin-left: 10px;
          font-size: 20px;
          cursor: pointer;
        }
      }
      .map-wrp {
        position: relative;
        overflow: hidden;
        height: 280px;
        background-color: #F1F1F1;
        border-radius: 24px;

        .map-placeholder {
          position: absolute;
          inset: 0;
          background-image: url('/public/images/frontend/map-placeholder.jpg');
          background-repeat: no-repeat;
          background-position: right center;
          background-size: auto 100%;

          .btn-wrp {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(6px)
          }
          #map {
            position: absolute;
            inset: 0;
            display: none;
            background-color: #e8eaed;

            &.initialized {
              display: block;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .product-top-part {
      .offer-intro-wrp {
        .content-links-wrp {
          overflow: auto;
          margin-left: -16px;
          margin-right: -16px;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
          .inner-wrp {
            .link-el {
              &:first-child {
                .content-link-btn {
                  margin-left: 16px;
                }
              }
              &:last-child {
                .content-link-btn {
                  margin-right: 16px;
                }
              }
            }
          }
        }
      }
      .recommendations-wrp {}
    }
    .product-search-part {
      .search-filters-wrp {
        .search-form-wrp {
          .filters-listing {
            overflow: auto;
            margin-left: -16px;
            margin-right: -16px;
            scrollbar-width: none;

            > .inner-wrp {
              .filter-group-wrp {
                .filter-group-items {
                  position: fixed;
                  bottom: -100%;
                  left: 0px;
                  right: 0;
                  z-index: 99999;
                  display: grid;
                  grid-template-columns: minmax(0, 1fr);
                  grid-template-rows: auto minmax(0, 1fr) auto;
                  max-height: calc(100% - 40px);
                  background-color: #ffffff;
                  border-radius: 24px 24px 0px 0px;
                  transition: all .3s;

                  .filter-group-items-header {
                    position: relative;
                    display: block;
                    padding: 18px 40px;
                    border-bottom: 1px solid #EBEBEB;

                    .title {
                      display: block;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      letter-spacing: 0px;
                      text-align: center;
                    }
                    .btn-close {
                      position: absolute;
                      top: 14px;
                      right: 12px;
                      opacity: 1;
                      padding: 8px;
                      background-size: 16px;
                    }
                  }
                  > .inner-wrp {
                  }
                  .filter-group-items-footer {
                    padding: 16px;
                    border-top: 1px solid #EBEBEB;
                  }
                  &.dates-group {
                    .dates-options-wrp {
                      @include dates-options-wrp-mobile();

                      .select-by-date-wrp {
                        .flex-days-wrp {
                          margin: 0px -16px;
                        }
                      }
                    }
                  }
                }

                &.open {
                  .filter-group-backdrop {
                    display: block;
                    opacity: 1;
                    animation: FadeInBlock 195ms ease-in-out;
                  }
                  .filter-group-items {
                    bottom: 0px;
                  }
                }
                &:first-child {
                  .filter-group-title {
                    margin-left: 16px;
                  }
                }
                &:last-child {
                  .filter-group-title {
                    margin-right: 16px;
                  }
                }
              }
            }
          }
        }
      }
      .offers-wrp {
        overflow: hidden;
        margin-left: calc(var(--side-gap) * -1);
        margin-right: calc(var(--side-gap) * -1);
        padding-left: var(--side-gap);
        padding-right: var(--side-gap);

        .offers-listing {
          .swiper {
            overflow: visible;
          }
          .offer-card {

          }
        }
      }
    }

    .hotel-details-part {
      .hotel-gallery-wrp {
        overflow: hidden;
        margin-left: calc(var(--side-gap) * -1);
        margin-right: calc(var(--side-gap) * -1);
        padding-left: var(--side-gap);
        padding-right: var(--side-gap);

        .hotel-gallery-swiper {
          overflow: visible;
        }
      }
      .hotel-features-wrp {
        .hotel-features-groups-wrp {
          &:not(.expanded) {
            .features-group-wrp {
              &:nth-child(n + 3) {
                display: none;
              }
            }
          }
          &.expanded {
            & + button {
              display: none;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .product-search-part {
      .offers-loading-wrp {
        span {
          width: 44.5%;
        }
      }
      .offers-wrp {
        .offers-listing {
          .swiper {
            &:not(.swiper-initialized) {
              .swiper-wrapper {
                column-gap: 14px;

                .swiper-slide {
                  width: calc((100% / 2.2) - ((14px * 2) / 3));
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .product-top-part {
      grid-template-columns: minmax(0, 610fr) minmax(0, 400fr);
      column-gap: 20px;

      .offer-intro-wrp {
        .product-title {
          margin-bottom: 16px;
          letter-spacing: 0.7px;
        }
        .location-wrp {
          margin-bottom: 26px;
        }
        .content-links-wrp {
          margin-bottom: 24px;

          .inner-wrp {
            flex-wrap: wrap;
          }
        }
        .hotel-desc {
          max-width: 520px;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
      }
      .recommendations-wrp {
        .recommendation-el {
          row-gap: 16px;
          padding: 32px 24px 34px 24px;

          .recommendation-header {
            grid-template-columns: minmax(0, 1fr) minmax(0, 88px);

            .photo-wrp {
              .recommend-badge {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }
    .product-search-part {
      margin-bottom: 80px;

      .search-filters-wrp {
        .search-form-wrp {
          .filters-listing {
            > .inner-wrp {
              .filter-group-wrp {
                position: relative;

                .filter-group-items {
                  position: absolute;
                  left: 0px;
                  top: 100%;
                  z-index: 11;
                  opacity: 0;
                  overflow: hidden;
                  display: grid;
                  grid-template-columns: minmax(0, 1fr);
                  grid-template-rows: minmax(0, 0fr);
                  margin: 8px 0px;
                  min-width: 260px;
                  max-width: 360px;
                  max-height: 360px;
                  width: max-content;
                  background-color: #ffffff;
                  border-radius: 24px;
                  box-shadow: 0px 2px 8px 0px #00000026;
                  transition: grid-template-rows .3s;


                  .filter-group-items-header, .filter-group-items-footer {
                    display: none;
                  }
                  .inner-wrp {
                    //overflow: hidden;
                    .dates-options-wrp {
                      @include dates-options-wrp-desktop();
                    }
                  }
                  &.dates-group {
                    max-height: initial;
                    max-width: 656px;
                    width: 656px;
                  }
                  &.night-group {
                    min-width: 300px;
                  }
                }
                &.open {
                  .filter-group-items {
                    grid-template-rows: minmax(0, 1fr);
                    opacity: 1;

                    .inner-wrp {

                    }
                  }
                }
              }
            }
          }
        }
      }
      .offers-loading-wrp {
        margin-bottom: 48px;

        span {
          width: 32.5%;
        }
      }
      .offers-wrp {
        .offers-listing {
          margin-bottom: 48px;

          .swiper {
            &:not(.swiper-initialized) {
              .swiper-wrapper {
                column-gap: 16px;

                .swiper-slide {
                  width: calc((100% / 3) - ((16px * 2) / 3));
                }
              }
            }
          }
          .swiper-nav-btn:not(.swiper-button-lock) {
            display: grid;
          }
          .offer-card {
            padding: 32px;

            .tags-wrp {
              margin-bottom: 32px;
            }
            .price-wrp {
              margin-bottom: 20px;

              .person-price-wrp {
                .price {
                  font-size: 40px;
                  line-height: 40px;
                }
                .old-price {
                  font-size: 15px;
                  line-height: 20px;
                }
                .price-prefix {
                  font-weight: 600;
                  font-size: 22px;
                  line-height: 28px;
                }
              }
              .total-price-wrp {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
              }
            }
            .details-wrp {
              margin-bottom: 28px;
            }
            .travel-dates-wrp {
              margin-bottom: 32px;

              .dates {
                font-size: 15px;
                line-height: 20px;
              }
              .flights-info {
                .btn-flights {
                  font-size: 15px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }

    .hotel-details-part, .offer-included-part, .location-part, .help-part {
      row-gap: 32px;

      .block-title {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .hotel-details-part {
      .hotel-features-wrp {
        grid-template-columns: repeat(5, minmax(0, 1fr));

        .hotel-features-groups-wrp {
          grid-column: 4 span;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 20px;
          row-gap: 26px;

          .features-group-wrp {
            align-content: start;
            row-gap: 20px;

            ul {
              row-gap: 14px;
            }
          }
        }
        button {
          display: none;
        }
      }
    }
    .offer-included-part {
      .offer-included-groups-wrp {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        column-gap: 20px;

        .offer-included-group {
          grid-column: 2 span;
        }
      }
    }
    .location-part {
      .location-content-wrp {
        row-gap: 24px;


        .map-wrp {
          height: 340px;
        }
      }
    }
    .help-part {
      display: none;
    }
    .offer-details-modal {
      .modal-dialog {
        max-width: 480px;

        .modal-content {
          max-height: 90vh;
          overflow: hidden;
        }
      }
      .block-title {
        &.big {
          font-size: 22px;
          line-height: 28px;
        }
      }
      .modal-body {
        @include custom-scrollbar();
        overflow: auto;
        padding: 24px 32px 56px 32px;

        .offer-summary-wrp {
          .summary-top {
            grid-template-columns: 99px minmax(0, 1fr);
            column-gap: 16px;
            margin-bottom: 20px;

            .name {
              font-size: 15px;
              line-height: 20px;
            }
          }
          .summary-bottom {
            row-gap: 16px;

            .price-wrp {
              .person-price-wrp {
                .price {
                  font-size: 28px;
                  line-height: 36px;
                }
              }
              .total-price-wrp {
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }
        .room-photos-wrp {
          gap: 10px;
          margin-bottom: 30px;
        }
        .room-details-wrp {
          row-gap: 16px;
          padding-bottom: 32px;

          .features-list-wrp {
            row-gap: 12px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .product-search-part {
      .offers-wrp {
        .offers-listing {
          .swiper-nav-btn {
            &.swiper-button-next {
              right: -62px;
            }
            &.swiper-button-prev {
              left: -62px;
            }
          }
        }
      }
    }
  }
}

#similarProducts {
  overflow: hidden;

  .swiper {
    overflow: visible;
  }
}