.modal.slide-up {
  .modal-dialog {
    .modal-content {
      .modal-header {
        position: relative;
        padding: 18px 40px;
        display: block;

        .modal-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
        }
        .btn-close {
          position: absolute;
          top: 21px;
          right: 20px;
          opacity: 1;
          background-size: 16px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    overflow: hidden;

    .modal-dialog {
      margin: 40px 0px 0px 0px;
      max-width: none !important;
      height: calc(100% - 40px);
      transform: translate(0, 100vh);

      .modal-content {
        height: 100%;
        border-radius: 24px 24px 0px 0px;

        .modal-header {
          position: relative;
          padding: 18px 40px;
          display: block;

          .modal-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
          }
          .btn-close {
            position: absolute;
            top: 21px;
            right: 20px;
            opacity: 1;
            background-size: 16px;
          }
        }
        .modal-body {
          overflow: auto;
          padding: 32px 16px;
        }
      }
      &.modal-dialog-centered {
        display: block;
        min-height: auto;
      }
    }
    &.show {
      .modal-dialog {
        transform: none;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      .modal-content {
        border-radius: 24px;

        .modal-header {
          .modal-title {}
          .btn-close {
            background-size: 14px;
          }
        }
      }
    }
  }
}
.modal.slide-lg-left {
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      margin: 0px 0px 0px auto;
      max-width: 360px;
      height: 100%;
      transform: translate(100vh, 0);

      .modal-content {
        height: 100%;
        border-radius: 24px 0px 0px 24px;
      }
    }
    &.show {
      .modal-dialog {
        transform: none;
      }
    }
  }
}