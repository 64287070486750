#inquiryHeader {
  padding: 16px 0px;

  .inquiry-header-inner {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto  minmax(0, 1fr);
    column-gap: 16px;
    align-items: center;

    .return-container {
      .return-link {
        display: none;
        padding: 6px 14px;
        font-size: 18px;
        color: $secondary-color;

        span {
          display: none;
        }
      }
    }
    .logo-container {
      img {
        height: 43px;
        width: auto;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 16px;
    padding: 30px 0px;

    .inquiry-header-inner {
      .logo-container {
        img {
          height: 49px;
        }
      }
    }
  }
}

.inquiry-form-wrp {
  .form-step-wrp {
    .inner-inquiry-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 40px;
      margin-bottom: 80px;

      .inquiry-banner-wrp {}
      .inquiry-fields-wrp {}
    }
    &:not([data-step="1"]) { // turi buti 1
      //display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .form-step-wrp {
      .inner-inquiry-wrp {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 22px;

        .inquiry-banner-wrp {
          order: 2;
        }
        .inquiry-fields-wrp {
          order: 1;
          align-items: start;
          grid-template-rows: min-content;
          margin-left: auto;
          margin-right: auto;
          max-width: 400px;
          width: 100%;
        }
      }
    }
  }
}

.inquiry-banner-wrp {
  position: relative;
  overflow: hidden;
  height: 360px;
  width: 100%;
  background-image: url('/public/images/frontend/inquiries/banner1.jpg?v=2');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  border-radius: 24px;

  .texts-wrp {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 12px;
    letter-spacing: 0px;

    .title {
      display: block;
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      font-family: 'Borna';
    }
    p {
      margin-bottom: 0px;
      font-size: 15px;
      line-height: 24px;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 560px;
    background-image: url('/public/images/frontend/inquiries/banner1.jpg?v=2');

    .texts-wrp {
      right: initial;
      left: 40px;
      bottom: 40px;
      row-gap: 16px;
      max-width: 420px;
      width: calc(100% - 80px);

      .title {
        font-size: 40px;
        line-height: 48px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.inquiry-options-title {
  display: block;
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
}
.inquiry-fields-wrp {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 40px;

  .inquiry-fields-top-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    justify-items: center;
    row-gap: 16px;

    .step-counter {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      padding: 5px 10px;
      min-width: 44px;
      text-align: center;
      background-color: #B5E0FF;
      border-radius: 10px;

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0px;
      }
    }
    .step-title {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0%;
      font-family: Borna;
      text-align: center;
    }
  }
  .inquiry-fields-bottom-wrp {
    .destination-inquiry-wrp {
      .destinations-options-search-wrp {
        position: relative;

        .fake-field {
          display: grid;
          grid-template-columns: 40px minmax(0, 1fr);
          column-gap: 12px;
          align-items: center;
          padding: 0px 16px 0px 0px;
          height: 56px;
          border: 1px solid $secondary-color;
          border-radius: 12px;
          box-shadow: 0px 0px 0px 0.5px $secondary-color;

          i {
            padding-left: 16px;
            font-size: 18px;
            text-align: center;
          }
          input {
            padding: 0px;
            font-size: 15px;
            line-height: 24px;
            background: transparent;
            border: none;

            &:focus-visible {
              outline: none;
            }
          }
          &.is-invalid {
            border-color: #FF0221;
            box-shadow: none;

            & + .error-message {
              display: block;
            }
          }
        }
        .error-message {
          display: none;
          margin-top: 4px;
          padding: 0px 16px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0px;
          color: #FF0221;
        }
        .custom-dropdown {
          position: absolute;
          top: calc(56px + 16px);
          overflow: hidden;
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: minmax(0, 0fr);
          max-height: 240px;
          width: 100%;
          background-color: #ffffff;
          box-shadow: 0px 2px 8px 0px #00000026;
          border-radius: 24px;
          opacity: 0;
          transition: grid-template-rows .3s, opacity .3s;

          .inner-wrp {
            overflow: auto;

            .destination-options-wrp {
              row-gap: 3px;
              padding: 16px;

              .destination-item {
                padding: 9px 16px;
                border-radius: 10px;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                  background-color: $violet-light-color;
                }
              }
            }
          }
          &.active {
            grid-template-rows: minmax(0, 1fr);
            opacity: 1;
          }
          &.top {
            top: initial;
            bottom: calc(100% + 16px);
          }
        }
      }
      .selected-destinations-wrp {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 6px;

        .selected-destination-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 8px;
          padding: 10px 16px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #D6D6D6;
          border-radius: 12px;
          cursor: pointer;

          i {
            font-size: 18px;
          }
        }
      }
    }
    .passengers-options-wrp {
      padding-top: 0px;

      .childs-age-title {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0px;
      }
    }
    .pills-options-wrp {
      .options-list-wrp {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 6px;

        label {
          display: flex;
          align-items: center;
          padding: 11px 15px;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0px;
          background-color: $violet-light-color;
          border-radius: 12px;
          transition: all .3s;
          cursor: pointer;

          input {
            display: none;
          }
          &:hover {
            background-color: $violet-medium-color;
          }
          &:has(input:checked) {
            background-color: $violet-color;
          }
        }
      }
    }
    .catering-options-wrp {
      .options-list-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 8px;

        label {
          padding: 20px 20px 20px 16px;
          background-color: $violet-light-color;
          border-radius: 14px;
          transition: all .3s;
          cursor: pointer;

          input {
            display: none;
          }
          .title {
            position: relative;
            display: grid;
            grid-template-columns: minmax(0, 1fr) auto;
            align-items: center;
            column-gap: 16px;
            padding-left: 32px;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0px;

            i {
              font-size: 24px;
            }
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0px;
              display: block;
              width: 20px;
              height: 20px;
              border: 1px solid $secondary-color;
              background-color: #ffffff;
              border-radius: 10px;
              transform: translateY(-50%);
              transition: all .3s;
            }
          }
          &:hover {
            background-color: $violet-medium-color;
          }
          &:has(input:checked) {
            background-color: $violet-color;

            .title:before {
              background-color: $secondary-color;
              box-shadow: inset 0 0 0 3px #ffffff;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .inquiry-fields-top-wrp {
      .step-title {
        font-size: 30px;
        line-height: 32px;
      }
    }
  }
}

.inquiries-thank-you-wrp {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding-bottom: 250px;
  padding-top: 100px;
  min-height: calc(100vh - 75px);

  .thanks-container {
    text-align: center;

    .icon-wrp {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        font-size: 26px;
        border-radius: 32px;
        background-color: #E3FF8C;
      }
    }
    .text-wrp {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 12px;

      .title {
        font-family: Borna;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0px;
      }
      .desc {
        margin: 0px;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0px;
      }
    }
    .actions-wrp {
      a {
        min-width: 230px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 139px);

    .thanks-container {
      .text-wrp {
        .title {
          font-size: 40px;
          line-height: 48px;
        }
        .desc {
          font-size: 18px; 
          line-height: 28px;
        }
      }
    }
  }
}

#inquiry-dates-block {
  
  .inquiry-variable-block {
    position: relative;

    span.value {
      padding: 25px 15px 5px;
      height: 56px;
      color: #191b46;
      border-radius: 12px;
      border-color: #d6d6d6;
      box-shadow: 0 0 0 0 #191b46;
    }
    
    .summary-wrp {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 5px;
      align-content: center;
      padding: 10px 20px;
      height: 100%;
      color: $secondary-color;
      border-radius: 38px;
      cursor: pointer;
      transition: all .3s;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      .value {
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    
    .custom-dropdown {
      position: absolute;
      top: calc(100%);
      overflow: hidden;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(0, 0fr);
      background-color: #ffffff;
      box-shadow: 0px 2px 8px 0px #00000026;
      border-radius: 24px;
      opacity: 0;
      transition: grid-template-rows .3s, opacity .3s;
      z-index: 1;
    }
    
    &.active {
      .custom-dropdown {
        grid-template-rows: minmax(0, 1fr);
        opacity: 1;
      }
    }

    &.dates-wrp {
      .summary-wrp {
        padding: 0;
      }
      
      .custom-dropdown {
        left: -161px;
        width: 656px;

        > .inner-wrp {
          .dates-options-wrp {
            @include dates-options-wrp-desktop();
          }
        }
      }
    }
    &.nights-wrp {
      .custom-dropdown {
        left: 0px;
        width: 358px;

        > .inner-wrp {
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          row-gap: 20px;
          padding: 20px 20px 24px 20px;

          .nights-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }
          .nights-options-wrp {
            padding-top: 0px;

            .night-item {
            }
          }
        }
      }
      
      .summary-wrp {
        padding: 25px 15px 5px 15px;
        height: 56px;
        color: #191B46;
        border-radius: 12px;
        border: 1px solid #191B46;
        box-shadow: 0px 0px 0px 0.5px #191B46;
        
        .title {
          position: absolute;
          top: 3px;
          font-weight: normal;
          font-size: 13px;
          color: #717184;
          padding-left: 16px;
        }
        
        .value {
          font-size: 15px;
          padding: 19px 3px 5px;
        }
      }
    }
  }
}

.inquiryModal {
  position: fixed;
  top: 0px;
  right: 0px;
  overflow: auto;
  overflow-x: hidden;
  z-index: 100001;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  height: 100%;
  background-color: #d9efff;
  transform: var(--drawer-transform-right-out);
  transition: var(--drawer-enter-transition-curve);

  &.open {
    transform: var(--drawer-transform-right-in);
    transition: var(--drawer-exit-transition-curve);
  }

  .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr) auto;

    .header-wrp {
      display: flex;
      justify-content: end;
      padding: 16px;

      .modal-close-btn {
        width: 48px;
        height: 48px;
        font-size: 24px;
        color: $secondary-color;
        background: none;
        border: none;
      }
    }
    .content-wrp {
      overflow: auto;
      padding: 0px 16px 16px 16px;

      .search-variables-wrp {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 8px;
        align-items: start;

        .search-variable-block {
          padding: 23px 20px;
          min-height: 64px;
          background-color: #ffffff;
          border-radius: 14px;
          transition: all .3s;

          .summary-wrp {
            display: grid;
            grid-template-columns: auto minmax(0, 1fr);
            column-gap: 16px;
            align-items: center;
            font-size: 15px;
            line-height: 18px;

            .title {
              font-weight: 600;
              color: $gray-color;
              transition: all .3s;
            }
            .value {
              font-weight: 500;
              color: $secondary-color;
              text-align: right;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              opacity: 1;
              transition: all .3s;
            }
          }

          &.destination-wrp {
            &.no-values {
              margin-bottom: 8px;
              padding: 28px 16px;
              border-radius: 24px;

              .summary-wrp {
                display: none;
              }
              .no-values-wrp {
                display: grid;
              }
            }
            .no-values-wrp {
              display: none;
              grid-template-columns: minmax(0, 1fr);
              row-gap: 16px;

              .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 27px;
              }
              .fake-field {
                display: grid;
                grid-template-columns: 24px minmax(0, 1fr);
                align-items: center;
                column-gap: 8px;
                padding: 16px;
                border: 1px solid $gray-color;
                border-radius: 12px;
                cursor: pointer;

                i {
                  font-size: 20px;
                }
                span {
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 18px;
                  color: $gray-color;
                }
              }
            }
            .destinations-drawer {
              position: fixed;
              top: 0;
              right: 0px;
              z-index: 999;
              overflow-x: hidden;
              overflow-y: auto;
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              align-content: start;
              row-gap: 32px;
              padding: 32px 16px 28px 16px;
              width: 100%;
              height: calc(100%);
              background-color: #ffffff;
              transform: translateX(100%);
              transition: all .3s;

              &.open {
                transform: translateX(0);
              }
            }
          }
          &.dates-wrp, &.nights-wrp, &.passengers-wrp {
            margin: 0px;
            transition: margin .3s;

            .summary-wrp {}
            .variable-content-wrp {
              display: grid;
              grid-template-columns: minmax(0, 1fr);
              grid-template-rows: minmax(0, 0fr);
              transition: grid-template-rows .3s;

              .variable-inner-wrp {
                overflow: hidden;
                margin: 0px -20px;
                padding: 0px 20px;
              }
            }
            &.active {
              margin: 8px 0px;
              padding: 28px 20px 23px 20px;
              border-radius: 24px;

              .summary-wrp {
                .title {
                  font-size: 24px;
                  line-height: 27px;
                  color: $secondary-color;
                }
                .value {
                  opacity: 0;
                }
              }
              .variable-content-wrp {
                grid-template-rows: minmax(0, 1fr);
              }
            }
          }
          &.dates-wrp {
            .variable-content-wrp {
              .dates-options-wrp {
                @include dates-options-wrp-mobile();
              }
            }
          }
        }
      }
    }
    .footer-wrp {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px;
      background-color: #ffffff;
      border-top: 1px solid #EBEBEB;
    }
  }
}