.collections_index_listing {
  .inner-wrp {
    &.type-2 {
      display: grid;
      grid-template-areas: "title"
                           "listing"
                           "button";
      grid-template-columns: minmax(0, 1fr);

      .title {
        grid-area: title;
        margin-bottom: 20px;
      }
      .btn {
        grid-area: button;
      }
      > .swiper {
        grid-area: listing;
        margin-bottom: 32px;

        .product-card {}
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .inner-wrp {
      &.type-2 {
        grid-template-columns: minmax(0, 1fr) auto;
        grid-template-areas: "title button"
                             "listing listing";
        align-content: center;
        row-gap: 20px;
        column-gap: 20px;

        .title {
          display: flex;
          align-items: center;
          margin: 0px;
        }
        .btn {
          min-height: 48px;
        }
      }
    }
  }
}