.contacts-page {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 48px;
  margin-bottom: 118px;

  .intro-block {
    .desc {
      font-size: 15px;
      line-height: 24px;
    }
  }
  .map-block {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    align-items: start;
    row-gap: 24px;

    .map-wrp {
      position: relative;
      overflow: hidden;
      height: 280px;
      width: 100%;
      border-radius: 24px;

      #map {
        position: absolute;
        inset: 0;
      }
    }
    .office-employee-wrp {
      display: grid;
      grid-template-columns: 56px minmax(0, 1fr);
      align-items: start;
      column-gap: 28px;
      padding: 20px;
      background-color: #E3FF8C;
      border-radius: 16px;

      .photo-wrp {
        position: relative;

        img {
          border-radius: 28px;
        }
        i {
          position: absolute;
          right: -10px;
          bottom: -6px;
          display: block;
          width: 28px;
          height: 28px;
          font-size: 17px;
          line-height: 28px;
          text-align: center;
          background-color: #FFF06B;
          border-radius: 8px;
          box-shadow: 0px 0px 0px 4px #E3FF8C;
        }
      }
      .texts-wrp {
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0px;
      }
    }
  }
  .content-block {
    text-wrap: balance;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: minmax(0, 1fr) minmax(360px, 420px);
    grid-template-areas: "intro map"
                         "content map";
    align-items: start;
    row-gap: 96px;
    column-gap: 74px;

    .intro-block {
      grid-area: intro;
    }
    .map-block {
      grid-area: map;

      .map-wrp {
        height: 500px;
      }
    }
    .content-block {
      grid-area: content;
    }
  }
}